import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate,NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';


import axios from 'axios'

// import ReactAudioPlayer from 'react-audio-player';

import AudioPlayer from './AudioPlayer';
import { AnimatePresence, motion } from "framer-motion";

// import Userivrnavbar from './Userivrnavbar';
import { CSVLink, CSVDownload } from "react-csv";


const BreakReport = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])

  useEffect(() => {
    document.title = "All BreakReport"
 }, []);


 
 const resetPwdFun = async (e) => {
    // alert(e)
      var formdata = new FormData()
   formdata.append('id', e)
   //formdata.append('callbackid', e2)
 const response = await fetch(process.env.REACT_APP_IP + "/User/ResetBreakPwd", {
     method: 'POST',
     // headers: {
     //     'Content-Type': 'application/json'
     // },

     headers: {


       "Authorization": `Bearer ${localStorage.getItem('token')}`
     },
      body: formdata
     // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
   });
   const json = await response.json()
   // console.log(json);
   if (json.type === "OK") {
     // props.showAlert(json.msg, "success");
     Swal.fire({
       position: 'top-end',
       icon: 'success',
       title: json.msg,
       background: '#e9ecef',
       // title: 'Done Successfully ',
       //background: primary,
       showConfirmButton: false,
       timer: 2500
     })
   } else {
     //props.showAlert(json.msg, "danger");
     Swal.fire({
       position: 'top-end',
       icon: 'error',
       title: json.msg,
       background: '#e9ecef',
       // title: 'Done Successfully ',
       //background: primary,
       showConfirmButton: false,
       timer: 2500
     })
   }

   getAllPosts();

 }

  
 
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);

  const [reportdaat, setreportdaat] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
     
       
     
            
              <tbody>
                {data.content.map(page => {
                  return (
                    <tr key={page.id} style={{whiteSpace:"nowrap"}}>
                      <td>{page.id}</td>                   
                      <td>{page.username}</td>
                      <td>{page.breakpwd}

                          <button className='btn btn-primary btn-mysm float-end' 
                           onClick={() => resetPwdFun(page.id)} 
                          >Reset</button>

                      </td>
                      
                      <td>{page.breakstarttime}</td>
                      <td>{page.breakendtime}</td>
                      <td >{page.breakreason}</td>                
                    </tr>
                  );
                })}
              </tbody>
            


           
       
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP+`/User/RestAllBreaklog?pageNumber=${offset - 1}&&
    size=10`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts();
  }, [offset])



  return (
  <>
        <div className='row me-3 ms-1'>
          <div className='col-md-12'>
            <div className="mt-1">             
              <div className='text-center card-header'>Break Report</div>
              
              <table className="table table-hover table-bordered checkoddata ignoreline">
             
              <thead className="bg-darkblue text-white">
                <tr>
                  <th>ID</th>                  
                  <th>Username</th>
                  <th>Break-Pwd</th>
                  <th>Break-Start-Time</th>
                  <th>Break-End-Time</th>
                  <th>Break-Reason</th>
                  
                 
                 
                  

                </tr>
              </thead>
                
              
        
              {posts}

              
             
            </table>
            

            Goto Pageno:<input type="number" className="col-md-1 mx-2 mb-2" id="gotopage" aria-describedby="gotopage"
              placeholder="gotopage" onChange={onInputChange} />

              
              {/* Using React Paginate */}            
            </div>
            <div className='row mb-1'>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <ReactPaginate
                     nextLabel={<i style={{color:'blue'}} className="fa fa-arrow-right fa-lg"></i>}
                     previousLabel={<i style={{color:'blue'}} className="fa fa-arrow-left fa-lg"></i>}                   
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    breakLinkClassName={"break-mef"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={offset - 1}               
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination2"}
                    activeClassName={"active"} />
              </div>
              <div className='col-md-4'></div>
            </div>
          </div>
        </div>           
      </>
    
  )
}

export default BreakReport
