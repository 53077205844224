import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import { CSVLink, CSVDownload } from "react-csv";
import ReactAudioPlayer from 'react-audio-player';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';




const AddAgent = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    useEffect(() => {
        document.title = "Add Agent"
     }, []);

    const csvData = [
        ["Number"]
      ];

    const el = useRef();


    const [campaignname,setCampaignname] = useState();
    const [uploadFile, setUploadFile] = useState();
    const [listname, setListname] = useState();

    const [agentnumber, setAgentnumber] = useState();
    const [agentname, setAgentname] = useState();

    


    const optionforlist = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/RestAllcampname", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
      
            headers: {
                'Content-Type': 'application/json',
      
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
      
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)
      
        var elelist = document.getElementById('campnameid');
        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['id'] + '">' + json[i]['campname'] + '</option>';
        }
      
       
      
        
      
      
      }


      const [isToggled, setIsToggled] = useState(false);
      const onToggle = async (e) => {
          const id = e
          // alert(id)
          const response = await fetch(process.env.REACT_APP_IP+"/User/ActiveAgentobd", {
              method: 'POST',
              // headers: {
              //     'Content-Type': 'application/json'
              // },
  
              headers: {
                  'Content-Type': 'application/json',
  
                  "Authorization": `Bearer ${localStorage.getItem('token')}`
              },
              body: JSON.stringify({
                  "id": id
  
              })
  
              // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
          });
          const json = await response.json()
          console.log(json);
          if(json.type==="OK")
        {
           // props.showAlert(json.msg, "success");
           Swal.fire({
            position: 'top-end',
             icon: 'success',
            // iconHtml:'<b></b>',
            title: json.msg,
        //    title: 'Success',
            background: '#e9ecef',
            width:'250px',
            height:'150px',
           //size:'10px',
           // title: 'Done Successfully ',
           //background: primary,
            showConfirmButton: false,
            timer: 2500
          })
        }else{
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
               // title: 'Done Successfully ',
               //background: primary,
                showConfirmButton: false,
                timer: 2500
              })
        }
          getAllPosts()
          setIsToggled(!isToggled);
  
  
      }
     
      
      useEffect(() => {
        optionforlist()
      }, [])

      const deleteCampaign = async (e) => {

        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteIvrAgent", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
       const json = await response.json()
       console.log(json.msg)
       if (json.type === "OK") 
       {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: "Success",
            background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
           text: json.msg,
            showConfirmButton: false,
            timer: 1500
          })

       }else{

        Swal.fire({
            position: 'top-end',
            icon: 'error',
                title: 'error',
                background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
           text: json.msg,
            showConfirmButton: false,
            timer: 1500
          })
       }
        
        getAllPosts()
        




    }


    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }

    const addagentonsubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        //formData.append('file', uploadFile)
        formData.append('campaignname', campaignname)
        formData.append('agentname', agentname)
        formData.append('agentnumber', agentnumber)

        
        

        const response = await fetch(process.env.REACT_APP_IP+"/User/AddDeleteAgentAction", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        if (json.type === "OK") 
       {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: "Success",
            background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
           text: json.msg,
            showConfirmButton: false,
            timer: 1500
          })

       }else{

        Swal.fire({
            position: 'top-end',
            icon: 'error',
                title: 'error',
                background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
           text: json.msg,
            showConfirmButton: false,
            timer: 1500
          })
       }
        getAllPosts()
        setAgentnumber('')
        setAgentname('')

       // el.current.value = "";



        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }



    const downloadsample = async (e) => {
        e.preventDefault();
      
        const response = await fetch(process.env.REACT_APP_IP+"/User/api/foo.csv", {
            method: 'GET',
           headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        });
        const json = await response.json()
       // console.log(json)
        props.showAlert(" Downloaded Successfully ","success");
        getAllPosts()    
    }

   





    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/AllCallpatchAgent?pageNumber=${offset - 1}&&size=5`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>

                <div className='row mt-1 '>

                <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1.5 }}
                exit={{ opacity: 0, duration: 1.5 }}
            //   exit={{scale: 1,animate scale: 0}}

            >

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12' >

                        <div className='card  ' style={{
                            
                        }}>

                            <div >
                                <h2 className='card-header text-center'>Existing Agent</h2>

                                <div className="table-responsive mt-3">

                                    <table className="table table-hover table-bordered checkoddata ignoreline">
                                        <thead className="bg-darkblue text-white" >
                                            <tr>
                                                <th>ID</th>
                                                <th>campaignname</th>
                                                <th>Name</th>
                                                <th>Number</th>
                                                <th>Active</th>
                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody className='text-black'>
                                            {data.content.map(page => {
                                                return (
                                                    <tr style={{Color:'black'}} key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.campname}</td>
                                                        <td>{page.agentname}</td>
                                                        <td>{page.agentnumber}</td>
                                                        <td className='text-center'>

                                                                <label className="toggle-switch">
                                                                    <input type="checkbox" checked={page.status === "Active"} onChange={() => onToggle(page.id)} />
                                                                    <span className="switch" />
                                                                </label>

                                                        </td>
                                                        <td className='text-center'> <i className="fa fa-trash  btn btn-danger btn-sm " onClick={() => deleteCampaign(page.id)} ></i></td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>
                    </motion.div>

                </div>


            </>

        )

    }


   


    return (

        <>
       
        
  

        <div className='row mt-1 p-1'>




            {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
            <div className='col-md-3 col-12 text-black '>


                <form className='card mybgcolor p-1' style={{  marginTop: "1px"
                }} onSubmit={addagentonsubmit}>
                    <h4 className='card-header text-center'>Add Agent</h4>
                    <div className=" mb-1 mt-2">
                          <label>Campaign Name</label>
                         <select id="campnameid" className="form-select" name="campaignname"  onChange={(e) => setCampaignname(e.target.value)} required>
                            <option value="">-- Select Campaign --</option>
                            

                        </select>
                       
                    </div>

                    <div className="mb-1">
                        <label>Agent Name</label>
                        <input name="agentname" type="text" className=" form-control form-control-sm" value={agentname}
                            onChange={(e) => setAgentname(e.target.value)} required />

                        
                    </div>

                    <div className="mb-1">
                        <label>Agent Number</label>
                        <input name="agentnumber" type="text" className=" form-control 
                         form-control-sm" value={agentnumber}
                            onChange={(e) => setAgentnumber(e.target.value)} required />

                        
                    </div>

                
                             <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                  

                 
                    

                    
                </form>

            </div>


            <div className="col-md-9 col-12" >

                {/* Display all the posts */}
                {posts}

                <div className="row">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12 mb-5">


                        <ReactPaginate
                            previousLabel={<i className="fa-solid fa-angles-left"></i>}
                            nextLabel={<i className="fa-solid fa-angles-right"></i>}
                            breakLabel={".."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



            </div>




        </div>

        </>
    )


}


export default AddAgent
