import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'

import { useLocation } from "react-router"

import Swal from 'sweetalert2';

// import AddCampaignform from './AddCampaignform';


const Editgui = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    // const options = [
    //     { value: 'ii', label: 'Chocolate' },
    //     { value: 'hh', label: 'Strawberry' },
    //     { value: 'gg', label: 'Vanilla' }
    //   ]

    const location = useLocation();
    const state = location.state;
    
  




    
    const [dropdown , setDropdown] = useState();
    const [ftype , setFtype] = useState();
    const [fieldname , setFieldname] = useState();
    const [order , setOrder] = useState();
    const [selectedOption, setSelectedOption] = useState(null);

    const ref = useRef(null)
    const refClose = useRef(null)
    const [ecampaignid, setEcampaignid] = useState()
    const [ecampaign, setEcampaign] = useState()
    const [ecampaignmode, setEcampaignmode] = useState()
    const [estarttime, setestarttime] = useState()
    const [eendtime, setEendtime] = useState()
    const [eselectaudio, setEeselectaudio] = useState()
    const [eselectlist,setEeselectlist] = useState()

    const [esubmitbutton, setesubmitbutton] = useState()

    // const { campaign, updateCampaign } = props;
   // deleteCampaign
    const deleteAgentcrm = async (e) => {

        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP+"/Admin/DeleteAgentcrm", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
       const json = await response.json()
       console.log(json.msg)
       if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'error',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 4500
        })
    }
        
        getAllPosts()
        




    }


    const updateCampaign = async (e) => {

        ref.current.click();
        //alert(e);
        const response = await fetch(process.env.REACT_APP_IP+"/User/getCampaignById", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": e

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        // console.log(json);
        // console.log(json.campname);
        // console.log(json.campaign_mode);

        setEcampaign(json.campname)
        setEcampaignmode(json.campaign_mode)
        setEcampaignid(json.id)
        setestarttime(json.start_time)
        setEendtime(json.end_time)
        setEeselectaudio(json.audioid | json.audio_name)
        setEeselectlist(json.listname)
        setmaxtry(json.maxtry)
        
        //getAllPosts()
        //setIsToggled(!isToggled);




    }

    // const handleClick = (e)=>{ 
    //     editNote(e.id, e.ecampname, e.ecamp_mode)
    //     refClose.current.click();
    // }


    const submiteditform = async (e) => {
        e.preventDefault();

        console.log(ecampaignid + ">>>>>>>" + ecampaign + ">>>>>>>>>" + ecampaignmode + ">>>>>>>>>" + estarttime
            + ">>>>>>>>" + eendtime + ">>>>>>>>>>>" + eselectaudio)

            var formdata = new FormData()
            formdata.append('id', ecampaignid)
            formdata.append('campaign', ecampaign)
            formdata.append('campaign_mode', ecampaignmode)
            formdata.append('start_time', estarttime)
            formdata.append('end_time', eendtime)
            formdata.append('audioid', eselectaudio)
            formdata.append('listname', eselectlist)
            formdata.append('maxtry', maxtry)
            

            
            


            const response = await fetch(process.env.REACT_APP_IP+"/User/EditCampaign", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
    
                headers: {
                    // 'Content-Type': 'application/json',
    
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
    
                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });

            ref.current.click();
            getAllPosts()

    }


    const [isToggled, setIsToggled] = useState(false);
    const onToggle = async (e) => {
        const id = e
        // alert(id)
        const response = await fetch(process.env.REACT_APP_IP+"/User/StartStopCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": id

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        props.showAlert("Done Successfully ", "success");
        getAllPosts()
        setIsToggled(!isToggled);


    }
    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/Admin/AllAgentCrm?pageNumber=${offset - 1}&&size=10&&campid=${state.id}`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");
    const [audio, setAudio] = useState();
    const[listname,setList] = useState();



    // const setAudiooption = async(event) =>{
    //     setAudio()
    //     console.log(event)

    // }
    const addcrmfieldSubmit = async (event) => {
        event.preventDefault();
        //alert(`The name you entered was: ${campname}`)

        const response = await fetch(process.env.REACT_APP_IP+"/Admin/UpdateAgentCrm", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "campid": state.id,
                "campname": state.name,
                "fieldlabel": fieldname,
                "fieldtype": ftype,
                "fieldoptions": dropdown,
                "fieldid":order
               
            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }
        //history('/AddCampaign');
        // alert("Campaign Added");
        setformyalert(!formyalert);
        getAllPosts()
        setCampname('')
        //setFtype('')
        setFieldname('')


    }




    const getPostData = (data) => {
        return (
            <>

                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12 mt-1'>

                        <div className='card p-1 ' 

                        // style={{ backgroundImage: "linear-gradient(to right, #a7a2ee 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)" }}
                        
                        >



                            <div className=' text-black '>
                                <h4 className='text-center card-header'>Field For Campaign id {state.id} and Campaign name {state.name} </h4>

                                <div className="table-responsive p-1">

                                    <table className="table table-hover table-bordered checkoddata ignoreline">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                {/* <th>ID</th> */}
                                                <th>Order</th>
                                                <th>Campaign Id</th>
                                                <th>Campaign Name</th>
                                                <th>Field Name</th>
                                                <th>Field Type</th>
                                                <th>DropDown Data</th>
                                                
                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        {/* <td>{page.id}</td> */}
                                                        <td>{page.fieldid}</td>
                                                        <td>{page.campid}</td>
                                                        <td>{page.campname}</td>
                                                        <td>{page.fieldlabel}</td>
                                                        {/* <td>{page.camp_status}</td> */}

                                                        <td>{page.fieldtype}</td>
                                                        <td>{page.fieldoptions}</td>
                                                       
                                                        <td> <button className="btn btn-danger btn-mysm">
                                                        <i className="fa fa-trash mx-2 " onClick={() => deleteAgentcrm(page.id)}></i>
                                                            </button></td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }


    return (


        <>


        <div className='row mt-1 '>
            {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
            <div className='col-md-3 col-12'>
                <form className='card p-1  '  onSubmit={addcrmfieldSubmit}>
                    <div className='card-header text-black'>
                        <h4>Add Field</h4>
                    </div>

                    <div className=" mb-1 mt-3">
                          <label>Order</label>
                         <select id="order" className="form-select" name="order"  onChange={(e) => setOrder(e.target.value)} required>
                            <option value="">Select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            
                        </select>
                       
                    </div>


                    <div className=" mb-1">
                          <label>FieldName</label>
                          <input type="text" className="form-control" id="fieldname" placeholder="Enter FieldName"
                            name="fieldname" value={fieldname}
                            onChange={(e) => setFieldname(e.target.value)} required />
                       
                    </div>

                    <div className=" mb-1">
                          <label>Field Type</label>
                         <select id="ftype" className="form-select" name="ftype"  onChange={(e) => setFtype(e.target.value)} required>
                            <option value="">Select</option>
                            <option value="TEXT">TEXT</option>
                            <option value="DROPDOWN">DROPDOWN</option>
                            <option value="Label">Label</option>
                           
                            
                        </select>
                       
                    </div>
                    <div className=" mb-3">
                          <label>DropDown value</label>
                          <input type="text" className="form-control" id="dropdown" placeholder="DropDown  value with comma"
                            name="dropdown" value={dropdown}
                            onChange={(e) => setDropdown(e.target.value)}  />
                       
                    </div>

                  


                  


                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                </form>

            </div>


            <div className="col-md-9 col-12">

                {/* Display all the posts */}
                {posts}

                <div className="row mb-5">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12">


                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



            </div>




        </div>

        </>
    )


}


export default Editgui
