import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate,NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';

import axios from 'axios'

// import ReactAudioPlayer from 'react-audio-player';

import AudioPlayer from './AudioPlayer';
import { AnimatePresence, motion } from "framer-motion";




function Agentcalllog(){
  let history = useNavigate();
  const location = useLocation();
 
  const [hidenuma, setHidenuma] = useState(true);
  var busyflag = true;
  const userCamphidelog = async (e) => {
   
      fetch(process.env.REACT_APP_IP+"/User/getCampaignHideStatus", {
        method: 'POST',
        headers: {
           // 'Content-Type': 'application/json',
  
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
  
        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    .then(response => {
      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the response as text
      return response.text();
    })
    .then(datab => {   
          setHidenuma(datab);
          busyflag = datab;       
          //alert(datab+">>>>"+hidenuma+">>>>>"+location.pathname)       
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
    }
   
  

  useEffect(() => {
    userCamphidelog();
  }, [location.pathname])
    
 // }, [location.pathname])


  const obscurePhoneNumber = (phoneNumber) => {
   
    // Check if phoneNumber exists and has at least 4 characters
   // if (busyflag && phoneNumber.length >= 4 ) {
    //alert(busyflag)
    if(busyflag==="true" && phoneNumber.length > 9) {
     // alert(busyflag)
        const obscuredPart = 'x'.repeat(phoneNumber.length - 4); // Replace all but the last 4 digits with 'x'
        const lastFourDigits = phoneNumber.slice(-4); // Extract the last 4 digits
        return obscuredPart + lastFourDigits; // Concatenate the obscured part with the last 4 digits
    } else {
        return phoneNumber; // Return the original phoneNumber if it doesn't meet the conditions
    }
  }



  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])

  useEffect(() => {
    document.title = "Agent Calllog"
 }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
      scale: 1
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 1.2
    }
  };
  
  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 1.0
  };
  
  const pageStyle = {
    // position: "absolute"
  };

  


 

  
 
 
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);

  const [reportdaat, setreportdaat] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
     
       
        <div className=''>
       
          <div className=" table-responsive p-1">
          <motion.div  
               initial={{ rotate: 270 }}
               animate={{ x: 0, opacity: 2.0,rotate: 360}}
               transition={{ duration: 0.5}}            
  >
            <table className="table table-hover table-bordered checkoddata ignoreline">
              <thead className="bg-darkblue text-white nowrap">
                <tr>
                  <th>ID</th>

                  <th>Caller</th>
                  {/* <th>Action</th> */}
                  <th>Calltype</th>
                  <th>Agents</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Duration</th>
                  <th>Dispo</th>
                 
                  <th>Status</th>
                  <th>Remarks</th>
                  <th>Recording</th>
                 
                 
                  

                </tr>
              </thead>
              <tbody>
                {data.content.map(page => {
                  return (
                    <tr key={page.id} style={{whiteSpace:"nowrap"}}>
                      <td>{page.id}</td>

                      <td>{obscurePhoneNumber(page.fone)}</td> 
                       {/* <td>{hidenuma ? "XXXXXX"+page.fone.slice(-4) :page.fone}</td> */}
                       {/* <td>{page.fone}</td>  */}
                      {/* <td className={hidenuma ? "hide" : ""}>{"XXXXXX"+page.fone.slice(-4)}</td> */}
                      {/* <td>
                      <button className=" mx-2 btn btn-primary btn-mysm disabled" >
                                                                <i className="fa fa-phone" ></i>
                                                            </button>
                                                            </td> */}
                     
                      <td>{page.type}</td>
                      <td>{page.agentid} | {page.agentsip}</td>
                      <td >{page.dialtime}</td>
                      <td>{page.customerhanguptime}</td>
                      <td>{page.totalduration}</td>
                      <td>{page.dispoone}</td>
                    
                      <td>{page.dialstatus}</td>
                      <td>{page.remarks}</td>
                      {/* <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}} onClick={() => clicktocallMethod(page.id)} ></i></td> */}
                     
                      {/* <td><i className="fa fa-phone"  ></i></td>  */}
                     
                      
                      {/* <td className='bg-secondary'> */}
                      <td>
                          {/* <ReactAudioPlayer 
                                    src={process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname}
                              controls /> */}

                              <AudioPlayer  src={process.env.REACT_APP_REC_LINK+page.userid+'/'+page.fold+'/'+page.recname} /> 

                             {/* <AudioPlayer  src={process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname} /> */}

                      </td>

                  
                      {/* <td><i className="fa fa-arrow-circle-o-up fa-2xl btn btn-info" style={{marginLeft:"15px"}}  ></i></td> */}


                    </tr>
                  );
                })}
              </tbody>
            </table>

            Goto Pageno:<input type="text" className="col-md-1 mx-2 mb-2" id="gotopage" aria-describedby="gotopage"
              placeholder="gotopage" onChange={onInputChange} />

</motion.div>
            {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
          </div>
         
        </div>
       
      </>

    )

  }

  const [numbersearch, setNumbersearch] = useState('NA');

  const searchNumber =  (e) => {
    //  alert(campaigns)
  e.preventDefault()
  //setCampaigns(e)
   //alert(">>>>>>")
  // setCampaigns()
   getAllPosts()

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP+`/User/AgentCalllog?numbersearch=${numbersearch}&&pageNumber=${offset - 1}&&
    size=10`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
    //userCamphide()
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);
  }

  useEffect(() => {
    getAllPosts();
    
  
  }, [offset])

 

  


 



  return (
  <>
  
 
    <div className='row'>
       
        <div className='row'>
          <div className='col-md-12'>
 
            <div className="table-responsive">
              {/* <div className='card'>
                  <div className='card-header'>
                  
                    </div>

              </div> */}
              {/* {hidenuma} */}
              <h1 className='text-center card-header'>Report  </h1>


              {/* Display all the posts */}
              {posts}

              

              {/* Using React Paginate */}
              <div className="col-md-3 col-12">

<form 
 onSubmit={searchNumber} 
className='text-center'>
    <input type="text" className="form-control" id="searchnumber" name="searchnumber"
        placeholder="Number" 
        onChange={(e) => setNumbersearch(e.target.value)}
    // onChange={onInputChange}
        />
        <input type='submit' className='mt-1 btn btn-primary btn-mysm ' value="search" />

</form>

</div>

            </div>
            <div className='row'>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <ReactPaginate
                   previousLabel={<i style={{color:'blue'}} className="fa fa-arrow-left fa-lg">PRE</i>}
                   nextLabel={<i style={{color:'blue'}} className="fa fa-arrow-right fa-lg">NEXT</i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  breakLinkClassName={"break-mef"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  forcePage={offset - 1}

                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"} />

              </div>
              <div className='col-md-4'></div>
            </div>


          </div>


        </div>
       
      </div>

      

      </>

      
    
  )

  
}

export default Agentcalllog
