import React, { useState,useEffect } from 'react'
//import { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2'

const Changepwd = () => {

  const [pwd , setPwd] = useState()
  const [confpwd , setConfpwd] = useState()

  useEffect(() => {
    document.title = "Change Pwd"
 }, []);


const changepwdfun = async(e) => {
 
e.preventDefault();
var formdata = new FormData()
formdata.append('pwd', pwd)

if(pwd===confpwd)
{
    //alert("matched")
    const response = await fetch(process.env.REACT_APP_IP + "/Admin/Passwordchange", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        setPwd('')
        setConfpwd('')


}else{
    alert("pwd not matched")
    return
}

}



  return (
    <>
    
    <div className="row mt-2 ms-2 me-3">
        <div className="col-md-4">

        </div>
        <div className="col-md-4">
        <form className=' mt-2  p-2 card' onSubmit={changepwdfun}>
    <div className=' mb-1'>
        <div className='card-header text-center p-3 fs-3'>
            <div>Change Password</div>
        </div>
        <div className=' userpagebg p-5'>
             
             
                
                    <label>Enter New Password:</label>
                    <input className='form-control mt-2' type="password" 
                    onChange={(e) => setPwd(e.target.value)} name="pwd" value={pwd} id="pwd" required />


                    <label className='mt-2'>Confirm Password:</label>
                    <input className='form-control mt-2' type="password" name="confpwd" value={confpwd} onChange={(e) => setConfpwd(e.target.value)} required />


                    <div class="text-center mt-4">
                         <input className='btn btn-primary  form-control text-white' type="submit" value="submit" />
                    </div>
                    
                
                
            
                  
                    
            
        </div>
    </div>
  

    </form>
            
        </div>

        <div className="col-md-4">
            
        </div>
    </div>
   
    
    
    
    
    
    </>
  )
}

export default Changepwd