import "./App.css";
import { useEffect, useState} from 'react'
import SideBar from "./components/Sidebar/SideBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Messages from "./pages/Messages";
import FileManager from "./pages/FileManager";
import Analytics from "./pages/Analytics";
import Order from "./pages/Order";
import Saved from "./pages/Saved";
import Setting from "./pages/Setting";
import ChannelSetting from "./pages/ChannelSetting";
import AddCampaign from "./pages/AddCampaign" 
import CampDid from "./pages/CampDid";
import Callbackactive from "./pages/Callbackactive";
// import Login from "./pages/Login";
import Report from "./pages/Report";
import UploadAudio from "./pages/UploadAudio";
import FilterReport from "./pages/FilterReport";
import ChurnLeads from "./pages/ChurnLeads";
import Agentlog from "./pages/Agentlog";
import UploadNumber from "./pages/UploadNumber";
import AssignDid from "./pages/AssignDid";
import Editgui from './pages/Editgui';
import Calllog from "./pages/Calllog";
import Makegui from "./pages/Makegui";
import Filtercalllog from "./pages/Filtercalllog";
import Changepwd from "./pages/Changepwd";
import Ivr from "./pages/Ivr";
import Crm from "./pages/Crm";
import Realtime from "./pages/Realtime";
import Assignagent from "./pages/Assignagent";
import Assignlist from "./pages/Assignlist";

import Audio from "./pages/Audio";
import Group from "./pages/Group";
import Agent from "./pages/Agent";
import Break from "./pages/Break";
import Callback from "./pages/Callback";
import AddDisposition from "./pages/AddDisposition";
import Agentcalllog from "./pages/Agentcalllog";
import AgentPerformance from "./pages/AgentPerformance";
import DrawIvr from "./pages/DrawIvr";
import AddAgent from "./pages/AddAgent";
import Reportlive from "./pages/Reportlive";
import AllowIP from "./pages/AllowIP";
import BlockNumber from "./pages/BlockNumber";
import Leadsmanager from "./pages/Leadsmanager";

import SmsportSetting from "./pages/SmsportSetting";
import Addleadstatus from "./pages/Addleadstatus";
import Leadslog from "./pages/Leadslog";
import Smssuits from "./pages/Smssuits";
import Sharescreen from "./pages/Sharescreen";
import Callbackall from "./pages/Callbackall";
import Callbackreport from "./pages/Callbackreport";
import Callbackreportall from "./pages/Callbackreportall";
import BreakReport from "./pages/BreakReport";



















import Logout from "./pages/Logout";
function App() {
 

 
  return (
    <Router>
      
       <SideBar> 
        <Routes >
        {/* <Route path="/Login" element={<Dashboard />} /> */}
        
          
          <Route path="/Dashboard" element={<Dashboard commonProp="Dashboard" />} />
          <Route path="/" element={<Dashboard  commonProp="Dashboard"  />} />
          <Route path="/UploadAudio" element={<UploadAudio />} />
          
          <Route path="/Smssuits" element={<Smssuits />} />
          <Route path="/Leadslog" element={<Leadslog />} />
          <Route path="/Realtime" element={<Realtime  commonProp="Realtime" />} />
          <Route path="/AddDisposition" element={<AddDisposition />} />
          <Route path="/Callback" element={<Callback />} />
          <Route path="/Break" element={<Break />} />
          <Route path="/ChurnLeads" element={<ChurnLeads />} />
          <Route path="/Callbackactive" element={<Callbackactive />} />
          <Route path="/CampDid" element={<CampDid />} />
          <Route path="/AssignDid" element={<AssignDid />} />
          <Route path="/DrawIvr" element={<DrawIvr />} />
          <Route path="/AddAgent" element={<AddAgent />} />
          <Route path="/Reportlive" element={<Reportlive />} />
          <Route path="/AllowIP" element={<AllowIP />} />

          <Route path="/LeadsManager" element={<Leadsmanager />} />
          <Route path="/AddLeadStatus" element={<Addleadstatus />} />
          
          
          
          {/* <Route path="/Logout" element={<Logout />} /> */}
          <Route path="/Users" element={<Users />} />
          
          <Route path="/SmsportSetting" element={<SmsportSetting />} />
          <Route path="/Crm" element={<Crm />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/file-manager" element={<FileManager />} />
          <Route path="/order" element={<Order />} />
          <Route path="/saved" element={<Saved />} />
          <Route path="/settings" element={<Setting />} />
          <Route path="/ChannelSetting" element={<ChannelSetting />} />
          <Route path="/Changepwd" element={<Changepwd />} />
          <Route path="/CallLog" element={<Calllog />} />
          <Route path="/AddIvr" element={<Ivr />} />
          <Route path="/AddCampaign" element={<AddCampaign />} />
          <Route path="/Assignagent" element={<Assignagent />} />
          <Route path="/Assignlist" element={<Assignlist />} />
          <Route path="/Report" element={<Report />} />
          <Route path="/UploadNumber" element={<UploadNumber />} />
          

          <Route path="/FilterReport" element={<FilterReport />} />
          <Route path="/AgentPerformance" element={<AgentPerformance />} />

          <Route path="/Agentlog" element={<Agentlog />} />
          
          
          <Route path="/Makegui" element={<Makegui />} />

          <Route path="/Editgui" element={<Editgui />} />          
          <Route path="/Audio" element={<Audio />} />
          <Route path="/Group" element={<Group />} />
          <Route path="/Agent" element={<Agent />} />
          <Route path="/FilterCallLog" element={<Filtercalllog />} />
          <Route path="/Agentcalllog" element={<Agentcalllog />} />
          <Route path="/BlockNumber" element={<BlockNumber />} />
          <Route path="/Sharescreen" element={<Sharescreen />} />
          <Route path="/Callbackall" element={<Callbackall />} />
          <Route path="/Callbackreport" element={<Callbackreport />} />
          <Route path="/Callbackreportall" element={<Callbackreportall />} />
          <Route path="/BreakReport" element={<BreakReport />} />
          
          
          

          
          
          

          

          <Route path="*" element={<></>} />
        </Routes>
       </SideBar> 
    </Router>
  );
}

export default App;
