import React from 'react'

import { useState,useEffect } from 'react'
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';
import axios from 'axios'

const Break = () => {

    useEffect(() => {
        document.title = "Break"
     }, []);

    
   // const [disp , setDisp] = useState("")
    const[userbreak,setUserbreak] = useState("")
    const [posts, setAllPosts] = useState([]);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0)

    //const AddBreakFun = ()
    const AddBreakFun = async (e) => {
        e.preventDefault();
        //alert(">>>>"+camp+">>>>"+disp)
        //console.log("id>>>>>>>>" + e)
           
        var formdata = new FormData()
       // formdata.append('campid', camp)
        formdata.append('userbreak', userbreak)
        
        const response = await fetch(process.env.REACT_APP_IP+"/User/AddBreakreason", {
            method: 'POST',     
            headers: {
                //  'Content-Type': 'application/json',
        
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        setUserbreak("")
        
        // setGroupname("NA")
        //  set
        
        getAllPosts()
        //setCamp("")
     //   setDisp("")


    }

    //getAllPosts
const getAllPosts = async () => {
    const res = await axios.get(process.env.REACT_APP_IP+`/User/AllBreak?pageNumber=${offset - 1}&&size=6`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    const data = res.data;
    const postData = getPostData(data)
    // Using Hooks to set value
    setAllPosts(postData)
    // setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
}
useEffect(() => {
    getAllPosts()
}, [offset])


const getPostData = (data) => {
    return (
        <>

            <div className='row '>

                {/* <AddCampaignform /> */}


                < div className='col-md-12 col-12'>

                    <div className='card mt-1' >



                        <div className=''>
                                <div className='card-header text-black'>
                                    <h4 className='text-center'>Existing Break</h4>
                                </div>

                            <div className="table-responsive p-1">

                                <table className="table table-hover table-bordered checkoddata ignoreline">
                                    <thead className="bg-darkblue text-white">
                                        <tr>
                                            <th>S.N</th>
                                           
                                            <th>Break</th>
                                            
                                           
                                            

                                            <th>Action</th>
                                            {/* <th>Delete</th> */}


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.content.map(page => {
                                            return (
                                                <tr key={page.id}>
                                                    <td>{page.id}</td>
                                                   
                                                   
                                                    <td>{page.breakreason}</td>
                                                    

                                                    <td>
                                                        {/* <button className=" mx-2 btn btn-primary disabled" >
                                                            <i className="fa fa-edit" ></i>
                                                        </button> */}
                                                  <button className='btn btn-danger'>
                                                        
                                                  <i className="fa fa-trash mx-2"
                                                   onClick={() => deleteDispo(page.id)}
                                                   
                                                   ></i>

                                                  </button>
                                                

                                                    </td>





                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                Goto Pageno:<input type="number" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                    placeholder="gotopage" 
                                    onChange={onInputChange}
                                     />

                            </div>
                        </div>
                    </div>


                </div>

            </div>


        </>

    )

}

const deleteDispo = async (e) => {
    // e.preventDefault();
     //refaddgroupagent.current.click();
     console.log("id>>>>>>>>" + e)
    
     var formdata = new FormData()
     formdata.append('id', e)
   
     const response = await fetch(process.env.REACT_APP_IP+"/User/deleteBreakreason", {
         method: 'POST',     
         headers: {
             //  'Content-Type': 'application/json',
 
             "Authorization": `Bearer ${localStorage.getItem('token')}`
         },
         body: formdata
         // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
     });
     const json = await response.json()
     console.log(json);
     if (json.type === "OK") {
         // props.showAlert(json.msg, "success");
         Swal.fire({
             position: 'top-end',
             icon: 'success',
             title: json.msg,
             background: '#e9ecef',
             // title: 'Done Successfully ',
             //background: primary,
             showConfirmButton: false,
             timer: 2500
         })
     } else {
         //props.showAlert(json.msg, "danger");
         Swal.fire({
             position: 'top-end',
             icon: 'error',
             title: json.msg,
             background: '#e9ecef',
             // title: 'Done Successfully ',
             //background: primary,
             showConfirmButton: false,
             timer: 2500
         })
     }
     getAllPosts()
 }

const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

}
const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
}


  return (
    <>
    <div className='row'>

            <div className='col-md-3'>
                    <form className=' card mt-1 ' style={{minHeight: "100px"}} 
                        onSubmit={AddBreakFun}
                    >
                    <div className='card-header text-black'>
                    <h4>Add Break</h4>
                    </div>
                    <div className='car-body'>
                       
                    <div className=" mt-2 mb-2 ">
                    <label htmlFor="dispo">Break</label>
                    <input type="text" className="form-control" id="dispo" placeholder="break"
                    name="dispo" 
                        value={userbreak}
                        onChange={(e) => setUserbreak(e.target.value)} 
                    required />
                    <input className=" form-control text-white btn btn-primary mt-3" type="submit" value="Submit" />

                    </div>
                    </div>

                    </form>
            </div>
            <div className='col-md-9'>

    {posts}
                <div className="row mb-5">
                        <div className="col-md-3 col-12">
                            
                        </div>

                        <div className="col-md-6 col-12">
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                breakLinkClassName={"break-mef"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                forcePage={offset - 1}

                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}

                            />
                        </div>

               </div>
    
 </div>
</div>
    
    </>
  )
}

export default Break