import React from 'react'
import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { Tree, TreeNode } from "react-organizational-chart";

import { FcAutomatic } from 'react-icons/fc';
import { AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { GrAddCircle } from 'react-icons/gr';
import { motion } from "framer-motion";
import ReactPaginate from 'react-paginate';
import AudioPlayer from './AudioPlayer';








const DrawIvr = () => {


  useEffect(() => {
    document.title = "DrawIvr"
 }, []);

    const ref = useRef(null)
    
    const ivrref = useRef(null)

    


    const [lid, setLid] = useState();

    const [action, setAction] = useState("NA");
    const [actiondtmf, setActiondtmf] = useState("NA");
    const [dtmftrue, setDtmftrue] = useState(false);
    const [subaction, setSubaction] = useState("NA");

    
    
    


    const setChangeAction = async (event) => {

      //alert(">>>>>"+event)

      setActiondtmf(event)
      if(event==="Dtmf")
      {
        setDtmftrue(true)
      }else{
        if(dtmftrue===true)
        {
          setDtmftrue(false)

        }
        
      }
     // event.preventDefault();
      //setGroupname('')
     // refaddgroup.current.click();
     // alert("clicked>>>>>>>>>"+event);

     
    }

    const handlePageClick = (event) => {
      const selectedPage = event.selected;
      setOffset(selectedPage + 1)
  }


    const [data, setData] = useState([]);
    const [posts, setAllPosts] = useState([]);
     // const datalast=lastfun();
     // setAllPosts(datalast);
    useEffect(() => {
        // fetch("http://localhost:8080/GetTree")
        //   .then((response) => response.json())
        //   .then((data) => setData(data));
        //   const datalast=lastfun();
        //   setAllPosts(datalast);
        getAllPosts()
         
      }, []);

      function openModel(id)
      {
        //alert(">>>>"+id)
        setLid(id)

        ref.current.click();
      }


      function setIvrmodel(id)
      {
        ivrref.current.click();
        
       // alert("Setting"+id)
        setLid(id)
        optionDestination(id)
       // ref.current.click();
      }
      
      
      

      const deleteTree = async (id) => {   
        //alert("submit") 
       // setLid(id)    
        var formdata = new FormData()
       // formdata.append('name', lname)
        formdata.append('id', id)
        const response = await fetch(process.env.REACT_APP_IP +"/User/DelTree", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
    
            headers: {
                  //'Content-Type': 'application/json',
    
                 "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        getAllPosts()
        const json = await response.json()
        console.log(json);
        
      }

      
      const [childlname ,setChildlname] = useState()

      const [lname ,setLname] = useState()
      const submitAddLabel = async (event) => {   
        event.preventDefault();
        
        //alert("submit")     
        var formdata = new FormData()
        formdata.append('name', childlname)
        formdata.append('id', lid)
        const response = await fetch(process.env.REACT_APP_IP +"/User/AddTree", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
    
            headers: {
                  //'Content-Type': 'application/json',
    
                 "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
       // const json = await response.json()
      //  console.log(json);
      setChildlname('')
      setLid('')
      ref.current.click();
        getAllPosts()
       
      }
      
      const deleteIvr = async(event) =>{
        //event.preventDefault();
        console.log("event>>>>>>>>"+event)
        var formdata = new FormData()
        formdata.append('id', event)
        const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteIvroption", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
    
            headers: {
              //  'Content-Type': 'application/json',
    
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if(json.type==="OK")
        {
           // props.showAlert(json.msg, "success");
           Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: json.msg,
            background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
            showConfirmButton: false,
            timer: 2500
          })
        }else{
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
               // title: 'Done Successfully ',
               //background: primary,
                showConfirmButton: false,
                timer: 2500
              })
            }
    
            getPosts()
    
    }

      const createIvr = async (event) => {   
        event.preventDefault();
        
        //alert("submit")     
        var formdata = new FormData()
        formdata.append('did', ivrdid)
       // formdata.append('id', lid)
        const response = await fetch(process.env.REACT_APP_IP +"/User/AddFirstTree", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
    
            headers: {
                  //'Content-Type': 'application/json',
    
                 "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        getAllPosts()
        
       
      }


      const onInputChange = (event) => {

       // const offset = Math.ceil(event.target.value);
       // setOffset(offset);
       setLname(event.target.value)

    }

    const getPostData = (data) => {
      return (
        <>
       
         
          <div className='mybgcolor '>
         
            <div className=" table-responsive p-4">
            <motion.div
                 initial={{ rotate: 270 }}
  
                //  animate={{ x: 0 }}
                 animate={{ x: 0, opacity: 2.0,rotate: 360}}
                 transition={{ duration: 0.5}}
                // transition={{ type: "spring",bounce: 0.55,duration: 1.1}}
  
                // transition={{ type: "spring", duration: 5, bounce: 0.6 }}
  
    >
              <table className="table table-hover table-bordered checkoddata ignoreline ">
                <thead className="bg-darkblue text-white">
                  <tr>
                    <th>ID</th>
                    {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                  <th>UserId</th>:""}

                    <th>Ivrname</th>
                    <th>Action</th> 
                    <th>Audio</th>
                    <th></th>
                    <th>Dtmf</th>
                    <th>Subaction</th>
                    {/* <th>UserId</th> */}
                    {/* <th>Action</th> */}
                    {/* <th>audioid</th> */}
                 
                    {/* <th>Destid</th> */}
                    <th>Destination</th>
                    <th>Delete</th>
                    {/* <th>Desttype</th> */}
                 
                   
                    
                    {/* <th>Ivrid</th> */}
                     
                    {/* <th>Recording</th> */}
                   
                   
                    
  
                  </tr>
                </thead>
                <tbody>
                  {data.content.map(page => {
                    return (
                      <tr key={page.id} style={{whiteSpace:"nowrap"}}>
                        <td>{page.id}</td>
                        {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                  <th>{page.userid}</th>:""}
                        {/* <td>{page.userid}</td> */}
                        <td>{page.ivrname}</td>
                        <td>{page.action}</td>
                        <td>{page.audioid} | {page.audioname}
                        
                        </td>
                        <td><AudioPlayer  src={process.env.REACT_APP_REC_LINK+page.userid+'/'+page.audioname+'.wav'} /></td>
                        <td>{page.validdtmf}</td>
                        {/* <td>{page.audioid}</td> */}
                       
                        <td>{page.subaction}</td>
                        {/* <td >{page.destid}</td> */}
                        <td>{page.desttype} | {page.destname}</td>
                        {/* <td>{page.desttype}</td> */}
                       
                       
                        
                        {/* <td>{page.ivrid}</td> */}
                        <td>
                                                      
                          <button className=" mx-2 btn btn-danger" onClick={() => deleteIvr(page.id)}>
                                <i className="fa fa-trash" ></i>
                          </button>
                          

                      </td>
  
                      </tr>
                    );
                  })}
                </tbody>
              </table>
  
              {/* Goto Pageno:<input type="text" className="col-md-1 mx-2 mb-2" id="gotopage" aria-describedby="gotopage"
                placeholder="gotopage" onChange={onInputChange} /> */}
  
  </motion.div>
              {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
            </div>
           
          </div>
         
        </>
  
      )
  
    }
    const [offset, setOffset] = useState(1);
    const [dataposts, setDataposts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getPosts = async () => {
      const res = await axios.get(process.env.REACT_APP_IP+`/User/AllIvroptionbyuserid?pageNumber=${offset - 1}&&size=10`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
  
      const data = res.data;
  
      //console.log(data);
      // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)
  
      // For displaying Data
      const postData = getPostData(data)
  
      // Using Hooks to set value
      setDataposts(postData)
      //setPageCount(Math.ceil(data.length / postsPerPage))
      setPageCount(Math.ceil(data.totalPages))
    }


    useEffect(() => {
      try{
        if (localStorage.getItem('token')) {
          getPosts();        
        } else {
          
        }
        
      }catch(error)
      {
        console.log("Error")
      }
     
    }, [offset])


    const [valdtmf ,setValdtmf] = useState("NA")
    const [audio ,setAudio] = useState("NA")
    const [group ,setGroup] = useState("NA")

    const submitaddivr = async (event) => { 
      event.preventDefault();
     // alert("Done>>>>>"+lname+"<<<<>>>>"+audio+"<<<<<<<>>>>>>>"+actiondtmf+"<<<<>>>>>>>>"+valdtmf+"<<<<<<<<<<<"+
     // subaction+"<>>>>>"+group+">>>>"+lid)

     var formdata = new FormData()
        formdata.append('ivrname', lname)
        formdata.append('audiovar', audio)
        formdata.append('actiondtmf', actiondtmf)
        formdata.append('valdtmfvar', valdtmf)
        formdata.append('subaction', subaction)
        formdata.append('groupvar', group)
        formdata.append('ivrid', lid)

      //  "ivrname": lname,
        //     "audioid": audio,
        //     "action": actiondtmf,
        //     "dtmf": valdtmf,
        //     "subaction": subaction,
        //     "destid": group,
        //     "ivrid":lid

      const response = await fetch(process.env.REACT_APP_IP+"/User/AddIvroption", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
           // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
        ,
            body: formdata
        // body: JSON.stringify({
        //    // "id":'',
        //     "ivrname": lname,
        //     "audioid": audio,
        //     "action": actiondtmf,
        //     "dtmf": valdtmf,
        //     "subaction": subaction,
        //     "destid": group,
        //     "ivrid":lid
        // })

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'error',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 4500
        })
    }
       
      setLname('')
      setAudio('NA')
      setActiondtmf('NA')
      setValdtmf('NA')
      setSubaction('NA')
      setGroup('NA')
      setLid('')

      getPosts()
     
      ivrref.current.click();

    }

    const [atreedata, setAtreedata] = useState([]);
      const getAllPosts = async () => {         
        const res = await axios.get(process.env.REACT_APP_IP +`/User/GetTree`,
        { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } }
        )  
        //const datab = res.json();   
       // console.log(res.data);
        setData(res.data);
      


       // const postData = getTreeData(data)
       // setAtreedata(postData)


        //const postData = lastfun(res.data)
       // setAllPosts(postData)
         // setPageCount(Math.ceil(data.totalPages))
      }

      useEffect(() => {
        getTreeDatamethod(data)
      }, [data])

   const  getTreeDatamethod = (data) => {     
        const postData = getTreeData(data)
        setAtreedata(postData)
      };
      
      //AddChild
      function AddChild(id)
      {
          alert("ok its "+id)
 
      }

    const StyledNode = (props) => {
        const { title, subheader, avatar, id ,parent} = props;
        return (
            <>

            
                <div className="container">
                <div className=' text-center'> 
                     <div className=''>
                              <b style={{textTransform: 'capitalize',marginRight:'5px'}}>{title}</b>
                              {parent === null ?'':<AiFillDelete size="20px"
                              style={{cursor:'pointer'}}
                              color="#dc3545" data-bs-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete IVR Label " 
                                  onClick={() => deleteTree(id)} />}
                              
                      </div>
                   
                </div>
                
                    <div className=' text-white ' style={{ display: "inline-block", textAlign: "left" }}>
                         
                        <div className="text-center p-1">
                            {/* <div className='col-sm-4'>
                                 <div className="btn btn-primary btn-sm " onClick={() => openModel(id)}>+</div>
                            </div> */}

                            <div className='col-xs-4 mb-1 '>
                              <div  className="btn btn-primary btn-sm "
                               data-bs-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title="Add Next Label IVR" 
                               onClick={() => openModel(id)}>
                                   <GrAddCircle size="25px" />
                              </div>
                                  
                            </div>


                            
                            {/* <div className='col-sm-4'>
                                   <div className="btn btn-danger btn-sm " onClick={() => deleteTree(id)}>-</div>
                            </div> */}
                            {/* <div className='col-xs-4 mb-1'>
                              <div className="btn btn-danger btn-sm "
                              data-bs-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete IVR Label " 
                              onClick={() => deleteTree(id)}>
                                   <AiFillDelete size="25px" />
                              </div>
                                  
                            </div>     */}
                            <div className='col-xs-4 '>
                              <div className="btn btn-warning btn-sm " 
                              data-bs-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title="IVR Setting" 
                              onClick={() => setIvrmodel(id)}>
                                   <FcAutomatic size="25px" />
                              </div>
                                  
                            </div>                       
                            
                        </div>
                        {props.children}
                    </div>
                </div>
            </>

        );
    };


    const createList = (id) => {
        return data
          .filter((item) => item.parent === id)
          .map((item) => {
            return (
              <TreeNode
                key={"TreeNode-" + item.id}
                label={<StyledNode title={item.name} id={item.id} />}
              >
                {createList(item.id)}
              </TreeNode>
            );
          });
      };


      
      // const lastfun = (data) => {
        
            
      //   return(data.filter((item) => item.parent == null)
      //   .map((item) => (
      //     <Tree
      //       key={"Tree-" + item.id}
      //       lineWidth={"10px"}
            
      //       lineHeight={"50px"}
      //       lineBorderRadius={"4px"}
      //       label={<StyledNode title={item.name} id={item.id} parent={item.parent}/>}
      //     >
      //       {createList(item.id)}
      //     </Tree>
      //   ))) 
    
      
    
      // };




      const getTreeData = (data) => {
  return (
  


    data.filter((item) => item.parent == localStorage.getItem('uid'))
        .map((item) => (
          <Tree
           
            key={"Tree-" + item.id}
            lineWidth={"10px"}
            lineColor={"#198754"}
            lineHeight={"50px"}
            lineBorderRadius={"4px"}
            label={<StyledNode title={item.name} id={item.id} parent={item.parent}/>}
          >
             <>
          <div className=''>
           




           
            </div>
            </>
            {createList(item.id)}
            
          </Tree>

          
        )
        
        
        )

        ) 
      }

      const[ivrdid,setIvrdid] = useState("NA")

     // const [group ,setGroup] = useState("NA")

      const allAssigndid = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/allDidbyuserid", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
      
            headers: {
                'Content-Type': 'application/json',
      
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
      
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)
        
        var elelist = document.getElementById('addivrdidid');
        elelist.innerHTML = ""+('<option value="">NA</option>');
        
        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['did'] + '">' + json[i]['id'] +' | ' + json[i]['did'] + '</option>';
        }
      
      
        
      
      
      }
      
      useEffect(() => {
          allAssigndid()
      }, [])


      const allaudiofun = async (e) => {
        const resppe = await fetch(process.env.REACT_APP_IP+"/User/RestAllAudioSelect", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const jsona = await resppe.json()
        console.log(jsona.length)

        var ele = document.getElementById('audioid');
        ele.innerHTML = ""+('<option value="0">NA</option>');
        for (var i = 0; i < jsona.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            ele.innerHTML = ele.innerHTML +
                '<option  value="' + jsona[i]['id'] + '">' + jsona[i]['id'] + ' | '+ jsona[i]['audioname'] + '</option>';
               // json[i]['id'] + ' | '+json[i]['campname'] 
        }


        // var eleedit = document.getElementById('sel');
        // eleedit.innerHTML = ""+('<option value="0">NA</option>');
        // for (var l = 0; l < jsona.length; l++) {
        //     // POPULATE SELECT ELEMENT WITH JSON.
        //     eleedit.innerHTML = eleedit.innerHTML +
        //         '<option  value="' + jsona[l]['id'] + '">' + jsona[l]['audioname'] + '</option>';
        // }




    }


    useEffect(() => {
      allaudiofun()
  }, [])





  //group option

const optionforgroup = async (e) => {
  const respp = await fetch(process.env.REACT_APP_IP+"/User/allGroupbyuserid", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
  })
  const json = await respp.json()
  console.log(json.length)

  var elelist = document.getElementById('groupselectmodel');
  elelist.innerHTML = ""+('<option value="">NA</option>');

  for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
          '<option  value="' + json[i]['id'] + '">' + json[i]['id'] + ' | '+json[i]['campname'] + '</option>';
  }
}





const optionDestination = async (e) => {
  const resppalluser = await fetch(process.env.REACT_APP_IP+"/Admin/RestAllUserdrop", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
          'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
  })

  const resppallcamp = await fetch(process.env.REACT_APP_IP+"/User/allGroupbyuserid", {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
    }

    // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
})


var formdata = new FormData()
formdata.append('id', e)
const resppallchild = await fetch(process.env.REACT_APP_IP+"/User/GetTreeByParentid", {
  method: 'POST',
  headers: {
      //'Content-Type': 'application/json',

      "Authorization": `Bearer ${localStorage.getItem('token')}`
  },

  body: formdata
})

  const jsonuser = await resppalluser.json()

  const jsoncamp = await resppallcamp.json()

  const jsonchild = await resppallchild.json()

  


  var ele = document.getElementById('ivrdestid');
       
  // ele.innerHTML = '<optgroup label="Agents">'
   ele.innerHTML = ""
   //+('<option  value="NA">------Select-----</option>');
   
   for (var l = 0; l < jsonuser.length; l++) {
       // POPULATE SELECT ELEMENT WITH JSON.
       ele.innerHTML = ele.innerHTML +
           '<option className="myopt"   value="' + jsonuser[l]['id']+'|'+"Agents"+ '">' + jsonuser[l]['username'] +' | '+ jsonuser[l].userpro['sip']+'</option>';
   }
   var cd="";
    for (var l = 0; l < jsoncamp.length; l++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        cd = cd +
            '<option  value="' + jsoncamp[l]['id'] +'|'+"Campaign"+ '">' + jsoncamp[l]['id']+' | '+jsoncamp[l]['campname'] + '</option>';
    }

    var ab="";
    for (var l = 0; l < jsonchild.length; l++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        ab = ab +
            '<option  value="' + jsonchild[l]['id'] +'|'+"NEXTIVR"+ '">' + jsonchild[l]['name'] + '</option>';
    }

    ele.innerHTML = 
                    '<optgroup label="NA">'+'<option  value="' + "NA" +'|'+"NA"+ '">' + "NA" + '</option>'+('</optgroup>')+
                    '<optgroup label="Agents">'+ele.innerHTML+('</optgroup>')+
                    '<optgroup label="Campaign">'+cd+('</optgroup>')+
                    '<optgroup label="NEXTIVR">'+ab+('</optgroup>')
                    
                    
                    ;
  

}

// useEffect(() => {
//     optionDestination()
// }, [])


        

      return (

        <>      
        <div className='row mt-1'>
          <div className='col-md-2 mt-1 '>
                    
                    <form className='card ms-1 ' onSubmit={createIvr}>
                              <div className="mb-2 mt-2 ">

                                <div className='card-header mb-3'>
                                  Add IVR
                                </div>
                                  <label className='mt-1 mb-1'>Select Did</label>
                                  <select className="form-select" id="addivrdidid"
                                    value={ivrdid} 
                                     onChange={(e) => setIvrdid(e.target.value)}
                                  >
                                          {/* <option value="7317474747">7317474747</option>
                                          <option value="7317474748">7317474748</option> */}
                                          
                                    </select>

                                    <div className="mt-3">
                                         <input className="btn btn-primary form-control text-white " type="submit" value="AddIvr" />
                                   </div>
                                
                                </div>


                                
                    </form>
                    
          </div>

          <div className='col-md-10 mt-2'>

            {/* Add IVR */}
            <button ref={ivrref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#addivrmodal">
                                    IVR Modal
            </button>

            <div className="modal fade" id="addivrmodal" tabIndex="-1" aria-labelledby="addivrmodalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header bg-primary ">
                    <h5 className="modal-title" id="addivrmodalLabel">Add IVR</h5>
                    <button type="button" className="bg-danger text-red btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form className=" p-2" onSubmit={submitaddivr}>
                     

                      <div className="mb-2">
                        <label htmlFor="user" className='d-flex justify-content-start form-label'>Name</label>
                        <input type="text" className="form-control" 
                        placeholder="Enter Level Name"
                         value={lname} onChange={(e) => setLname(e.target.value)}  required />
                      
                      </div>

                      <div className="mb-2">
                        <label htmlFor="user" className='d-flex justify-content-start label form-label'>Audio</label>
                        <select className='form-control' id="audioid"
                          value={audio} onChange={(e) => setAudio(e.target.value)}
                         >
                                {/* <option value="NA">NA</option>
                                <option value="one">one</option> */}
                                
                          </select>
                      
                      </div>

                      <div className="mb-2">
                        <label htmlFor="user" className='d-flex justify-content-start form-label'>Action</label>
                        <select className='form-control'
                              value={actiondtmf} 
                                onChange={(e) => setChangeAction(e.target.value)}
                         >
                                <option value="Hangup">Hangup</option>
                                <option value="Dtmf">Dtmf</option>
                                <option value="AnyDtmf">AnyDtmf</option>
                                {/* <option value="PlayAudio">PlayAudio</option>
                                <option value="PlayDtmfAudio">PlayDtmfAudio</option> */}
                                <option value="CallForward">CallForward</option>
                                <option value="NEXT">NEXT</option>
                          </select>
                      
                      </div>

                    

                      { dtmftrue?

                      <div className="mb-2">
                        <label htmlFor="user" className='d-flex justify-content-start form-label'>Dtmf</label>
                        <input type="number" className="form-control" 
                          placeholder="dtmf"

                          value={valdtmf} onChange={(e) => setValdtmf(e.target.value)} 
                         
                         required />
                      
                      </div>
                      :""}

                      {/* <div className="mb-2">
                        <label htmlFor="user" className='d-flex justify-content-start label form-label'>NoDtmfAudio</label>
                        <select className='form-control'
                          value={group} onChange={(e) => setGroup(e.target.value)}
                         >
                                <option value="NA">NA</option>
                                <option value="one">one</option>
                                
                          </select>
                      
                      </div>
                      <div className="mb-2">
                        <label htmlFor="user" className='d-flex justify-content-start label form-label'>WrongDtmfAudio</label>
                        <select className='form-control'
                          value={group} onChange={(e) => setGroup(e.target.value)}
                         >
                                <option value="NA">NA</option>
                                <option value="one">one</option>
                                
                          </select>
                      
                      </div> */}

                    <div className="mb-2">
                        <label htmlFor="user" className='d-flex justify-content-start form-label'>SubAction</label>
                        <select className='form-control'
                              value={subaction} 
                                onChange={(e) => setSubaction(e.target.value)}
                         >
                                <option value="NA">NA</option>
                                <option value="Hangup">Hangup</option>
                                {/* <option value="Dtmf">Dtmf</option> */}
                                <option value="AnyDtmf">AnyDtmf</option>
                                {/* <option value="PlayAudio">PlayAudio</option>
                                <option value="PlayDtmfAudio">PlayDtmfAudio</option> */}
                                <option value="CallForward">CallForward</option>
                                <option value="NEXT">NEXT</option>
                          </select>
                      
                      </div>




                      <div className="mb-2">
                        <label htmlFor="user" className='d-flex justify-content-start label form-label'>Destination</label>
                        <select className='form-control' id="ivrdestid"
                          value={group} onChange={(e) => setGroup(e.target.value)}
                         >
                                <option value="NA">NA</option>
                                <option value="one">one</option>
                                
                          </select>
                      
                      </div>



                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                </div>
                     

                    </form>
                  </div>

                </div>
              </div>
            </div>
            {/* Add Next Level IVR */}
            <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                          Launch demo modal
            </button>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Child</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form className=" p-2" onSubmit={submitAddLabel}>
                     

                      <div className="mb-2">
                        <label htmlFor="user" className="form-label">Name</label>
                        <input type="text" className="form-control" 
                        placeholder="Enter Level Name"
                         value={childlname} onChange={(e) => setChildlname(e.target.value)}  required />
                      
                      </div>
                      <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                </div>
                     

                    </form>
                  </div>

                </div>
              </div>
            </div>



          {/* <h1 >Draw IVR</h1> */}

        {atreedata}

      

        </div>
        
        <div className='ms-1 mt-5 '>
             <div className='card'>
                <div className='card-header text-center bg-primary text-white'>
                        All-IVR
                        
                </div>
                <div className=''>
                {dataposts}
                        
                </div>
             </div>
                
                 
        </div>
        <div className='col-md-3'></div>

        <div className='col-md-6 col-12'>
<ReactPaginate
     previousLabel={<i style={{color:'blue'}} className="fa fa-arrow-left fa-lg">PRE</i>}
     nextLabel={<i style={{color:'blue'}} className="fa fa-arrow-right fa-lg">NEXT</i>}
    breakLabel={".."}
    breakClassName={"break-me"}
    breakLinkClassName={"break-mef"}
    pageCount={pageCount}
    onPageChange={handlePageClick}
    forcePage={offset - 1}

    containerClassName={"pagination"}
    subContainerClassName={"pages pagination"}
    activeClassName={"active"} />

</div>
      <div>

      <div className='col-md-3'></div>

     
     

</div>



        </div>

        

        </>
      )  
           
            
        
        
  
}

export default DrawIvr