import React, { useRef, useState } from 'react';

const Sharescreen = () => {
  const videoRef = useRef(null);
  const [isSharing, setIsSharing] = useState(false);

  const startScreenShare = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { cursor: "always" },
        audio: false, // Set to true if audio sharing is needed
      });
      videoRef.current.srcObject = stream;
      setIsSharing(true);

      // Stop sharing when the stream ends
      stream.getTracks()[0].addEventListener('ended', () => {
        stopScreenShare();
      });
    } catch (error) {
      console.error("Error starting screen share:", error);
    }
  };

  const stopScreenShare = () => {
    const stream = videoRef.current.srcObject;
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
    setIsSharing(false);
  };

  return (
    <div>
      <button onClick={isSharing ? stopScreenShare : startScreenShare}>
        {isSharing ? "Stop Sharing" : "Start Screen Sharing"}
      </button>
      <video ref={videoRef} autoPlay playsInline style={{ width: "100%", maxHeight: "400px" }} />
    </div>
  );
};

export default Sharescreen;
