import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-date-picker';
import axios from 'axios'
import dateFormat from "dateformat";
import { motion } from 'framer-motion';
// import Navbar from './Navbar';
// import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from './AudioPlayer';
import Swal from 'sweetalert2';


const Agentlog = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])


  useEffect(() => {
    document.title = "Filter Report"
  }, []);
  const [first, setFirst] = useState()
  const [second, setSecond] = useState("NA")
  const [third, setThird] = useState("NA")
  const [four, setFour] = useState("NA")
  const [five, setFive] = useState("NA")
  const [six, setFSix] = useState("NA")

  const [seven, setFSeven] = useState("NA")
  const [eight, setFEight] = useState("NA")
  const [nine, setFNine] = useState("NA")
  const [ten, setFTen] = useState("NA")

  const clicktocalfromreport = async (e) => {
    //e.preventDefault();
    //refclicktocall.current.click();
    //console.log(">>>>>>>>>>>>>>>>agentid>>>>>>>>>>>>>>>"+agentid)
    console.log(">>>>>>>>>>>>>>>>number>>>>>>>>>>>>>>>>" + e)
    var formdata = new FormData()
    // formdata.append('agentid', agentid)
    formdata.append('number', e)
    const response = await fetch(process.env.REACT_APP_IP + "/User/Clicktocallivr", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        //  'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Success',
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        text: json.msg,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'error',
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        text: json.msg,
        showConfirmButton: false,
        timer: 4500
      })
    }

    //setAgentid("")
    // setNumber("")



  }
  const [startdate, setstartdateval] = useState(new Date());

  const [enddate, setEnddateval] = useState(new Date());

  const [sddate, setsddate] = useState("NA");
  const [eddate, seteddate] = useState("NA");


  const [campaignname, setCampaignname] = useState("NA");

  const [keypress, setKeypress] = useState("ALL");

  const [opennav, setopennav] = useState();
  const [listname, setList] = useState();


  const setstartdate = (event) => {
    //alert(event)
    setstartdateval(event)
    var sttt = dateFormat(event, "yyyy-mm-dd")



    setsddate(sttt)



  }

  const optionforlist = async (e) => {

    const respp = await fetch(process.env.REACT_APP_IP + "/Admin/RestAllUserdrop", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('campnameid');

    elelist.innerHTML = "" + ('<option value="NA">ALL</option>');
    for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
        '<option  value="' + json[i]['username'] + '">' + json[i]['id'] + ' | ' + json[i]['username'] + '</option>';
    }






  }

  useEffect(() => {
    optionforlist()
  }, [])

  const setenddate = (event) => {
    setEnddateval(event)
    var enddd = dateFormat(event, "yyyy-mm-dd")
    seteddate(enddd)

  }

  const toggleClass = () => {
    //alert("clicked");
    if (!opennav) {
      setopennav(true);
    } else {
      setopennav(false);
    }
    // alert("clicked"+opennav);

  }

  const filterreportdownload = async () => {


    //var startdate=dateFormat(startdate, "yyyy-mm-dd")
    //var enddate=dateFormat(enddate, "yyyy-mm-dd")

    //window.open(process.env.REACT_APP_IP + `/User/reportdownloadbycampid/obdreport.csv`, '_blank', 'noopener,noreferrer');
    window.open(process.env.REACT_APP_IP + `/Admin/reportdownloadbycampid` + `?username=` + campaignname +
      `&startdate=` + dateFormat(startdate, "yyyy-mm-dd") + `&enddate=` + dateFormat(enddate, "yyyy-mm-dd") + `&keypress=` + keypress);


    // console.log("campaignname>>>>>>>"+campaignname)
    //console.log("keypress>>>>>>>"+keypress)
    // console.log("startdate>>>>>>>"+dateFormat(startdate, "yyyy-mm-dd"))
    //console.log("enddate>>>>>>>"+dateFormat(enddate, "yyyy-mm-dd"))



  }

  const filterreportsubmit = async (event) => {
    event.preventDefault();

    //const DATE_FORMATER = require( 'dateformat' );
    //var datetimef = DATE_FORMATER( startdate, "yyyy-mm-dd" );


    console.log(">>>>startdate>>>>>>>" + dateFormat(startdate, "yyyy-mm-dd") + ">>>>>enddate>>>>>>>"
      + dateFormat(enddate, "yyyy-mm-dd") + ">>>>>>campaignname>>>>>>" + campaignname + ">>>>>>keypress>>>>>>" + keypress);


    getAllPosts()




  }

  const [postsPerPage] = useState(5);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
        <div className='mybgcolor'>
          <motion.div
            // style={pageStyle}
            // initial={{ x: 300, opacity: 0 }}
            // // animate={{ x: 0, opacity: 1,rotate: 360}}
            // animate={{ x: 0, opacity: 1}}
            // exit={{ rotate: 360}}
            // transition={{
            //   delay: 1,
            //   x: { type: "spring", stiffness: 100 },
            //   default: { duration: 2 },
            // }}
            // variants={pageVariants}
            // transition={pageTransition}
            initial={{ rotate: 270 }}

            //  animate={{ x: 0 }}
            animate={{ x: 0, opacity: 2.0, rotate: 360 }}
            transition={{ duration: 0.5 }}

          >
            <div className="table-responsive p-1">
              <table className="table table-hover table-bordered checkoddata ignoreline">
                <thead className="bg-darkblue text-white">
                  <tr>
                    <th>ID</th>

                    <th>Action</th>
                    <th>Date</th>
                    <th>UserId | UserName</th>
                    <th>From IpAddress</th>


                  </tr>
                </thead>
                <tbody>
                  {data.content.map(page => {
                    return (
                      <tr key={page.id} style={{ whiteSpace: "nowrap" }}>
                        <td>{page.id}</td>
                        <td>{page.action}</td>
                        <td>{page.createdate}</td>
                        <td>{page.userid} | {page.username}</td>
                        <td>{page.ip}</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>

              Goto Pageno:<input type="number" className="col-md-1 mx-2 mb-2" id="gotopage" aria-describedby="gotopage"
                placeholder="gotopage" onChange={onInputChange} />
              {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
            </div>
          </motion.div>
        </div>
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);
    // alert(">>>>"+campaignname)

    const res = await axios.get(process.env.REACT_APP_IP + `/User/AgentLoginLog?pageNumber=${offset - 1}&&
    size=10&&startdate=${sddate}&&enddate=${eddate}&&keypress=${keypress}&&username=${campaignname}`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts()
  }, [offset])




  const testdata = async (e) => {

    console.log(">>>>>>>>>>>>>")
    setCampaignname(e.target.value)



  }


  return (

    <>



      <div className='row ms-1 me-3'>
        <div className='col-md-3'>
          <div className='card '>
            <form onSubmit={filterreportsubmit} >
              <div className='card-header'>
                <div className='text-center'>Agent Log</div>
              </div>
              <div className=" mb-2 mt-2">
                <label htmlFor="campaignname" className="form-label">Agent Name</label>             
                <select id="campnameid" className="form-select" name="campaignname" onChange={testdata} required>
                  <option value="">-- Select Agent --</option>
                </select>
              </div>
              <div className="mb-3 mt-2">
                <label htmlFor="campaignname" className="form-label">Start Date</label>
                <DatePicker className="form-control" onChange={setstartdate} value={startdate} />
              </div>
              <div className="mb-3 mt-2">
                <label htmlFor="campaignname" className="form-label">End Date</label>
                <DatePicker className="form-control" onChange={setenddate} value={enddate} />
              </div>
              <div className="d-grid gap-2 mb-1 mt-2">
                <input className="btn btn-primary " type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>

        <div className='col-md-9'>
          <div className="table-responsive card">
            <div className='card-header text-center'>
              <div>Agent Login Logout Report</div>
            </div>
            {/* Display all the posts */}
            {posts}
            {/* Using React Paginate */}
          </div>
          <div className='row mb-5'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <ReactPaginate
                previousLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-left fa-lg">PRE</i>}
                nextLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-right fa-lg">NEXT</i>}
                breakLabel={".."}
                breakClassName={"break-me"}
                breakLinkClassName={"break-mef"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                forcePage={offset - 1}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"} />
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

export default Agentlog
