import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import Swal from 'sweetalert2';
//import Select from 'react-select'
//import { Button } from 'react-bootstrap';
import { motion } from 'framer-motion';


// import AddCampaignform from './AddCampaignform';


const AddCampaign = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })


    useEffect(() => {
        document.title = "Add Campaign"
     }, []);
    // const options = [
    //     { value: 'ii', label: 'Chocolate' },
    //     { value: 'hh', label: 'Strawberry' },
    //     { value: 'gg', label: 'Vanilla' }
    //   ]

    
    const optionsa = async (e) => {
        const resppe = await fetch(process.env.REACT_APP_IP+"/User/RestAllAudioSelect", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const jsona = await resppe.json()
        console.log(jsona.length)

        var ele = document.getElementById('selmain');
        ele.innerHTML = ""+('<option value="0">NA</option>');
        for (var l = 0; l < jsona.length; l++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            ele.innerHTML = ele.innerHTML +
                '<option  value="' + jsona[l]['id'] + '">' + jsona[l]['audioname'] + '</option>';
        }


        var eleedit = document.getElementById('sel');
        eleedit.innerHTML = ""+('<option value="0">NA</option>');
        for (var l = 0; l < jsona.length; l++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            eleedit.innerHTML = eleedit.innerHTML +
                '<option  value="' + jsona[l]['id'] + '">' + jsona[l]['audioname'] + '</option>';
        }




    }

    const optionforlist = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/RestAllListname", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('list');
        elelist.innerHTML = ""+('<option value="0">NA</option>');
        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['listname'] + '">' + json[i]['listname'] + '</option>';
        }

        var elelistedit = document.getElementById('sellist');
        elelistedit.innerHTML = ""+('<option value="0">NA</option>');
        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelistedit.innerHTML = elelistedit.innerHTML +
                '<option  value="' + json[i]['listname'] + '">' + json[i]['listname'] + '</option>';
        }

        


    }

    useEffect(() => {
        optionforlist()
    }, [])

    useEffect(() => {
        optionsa()
    }, [])


    const [campaigns, setCampaigns] = useState('NA');

    const searchCampaign =  (e) => {
      //  alert(campaigns)
    e.preventDefault()
    //setCampaigns(e)
     //alert(">>>>>>")
    // setCampaigns()
     getAllPosts()

    }


    const [selectedOption, setSelectedOption] = useState(null);

    const ref = useRef(null)
    const refClose = useRef(null)
    const [ecampaignid, setEcampaignid] = useState()
    const [ecampaign, setEcampaign] = useState()
    const [ecampaignmode, setEcampaignmode] = useState()
    const [estarttime, setestarttime] = useState()
    const [eendtime, setEendtime] = useState()
    const [eselectaudio, setEeselectaudio] = useState()
    const [eselectlist,setEeselectlist] = useState()

    const [esubmitbutton, setesubmitbutton] = useState()

    // const { campaign, updateCampaign } = props;
   // deleteCampaign
    const deleteCampaign = async (e) => {

        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
       const json = await response.json()
       console.log(json.msg)
       if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'error',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 4500
        })
    }
        
        getAllPosts()
        




    }


    const updateCampaign = async (e) => {

        ref.current.click();
        //alert(e);
        const response = await fetch(process.env.REACT_APP_IP+"/User/getCampaignById", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": e

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        // console.log(json);
        // console.log(json.campname);
        // console.log(json.campaign_mode);

        setEcampaign(json.campname)
        setEcampaignmode(json.campaign_mode)
        setEcampaignid(json.id)
        setestarttime(json.start_time)
        setEendtime(json.end_time)
        setEeselectaudio(json.audioid)
        setEeselectlist(json.listname)
        setmaxtry(json.maxtry)
       setPace(json.pace)
        
        //getAllPosts()
        //setIsToggled(!isToggled);




    }

    // const handleClick = (e)=>{ 
    //     editNote(e.id, e.ecampname, e.ecamp_mode)
    //     refClose.current.click();
    // }


    const submiteditform = async (e) => {
        e.preventDefault();

        console.log(ecampaignid + ">>>>>>>" + ecampaign + ">>>>>>>>>" + ecampaignmode + ">>>>>>>>>" + estarttime
            + ">>>>>>>>" + eendtime + ">>>>>>>>>>>" + eselectaudio)

           

            var formdata = new FormData()
            formdata.append('id', ecampaignid)
            formdata.append('campaign', ecampaign)
            formdata.append('campaign_mode', ecampaignmode)
            formdata.append('start_time', estarttime)
            formdata.append('end_time', eendtime)
            formdata.append('audioid', eselectaudio)
            formdata.append('listname', eselectlist)
            formdata.append('pace', pace)
            formdata.append('maxtry', maxtry)
            

            
            


            const response = await fetch(process.env.REACT_APP_IP+"/User/EditCampaign", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
    
                headers: {
                    // 'Content-Type': 'application/json',
    
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
    
                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });

            ref.current.click();
            getAllPosts()

    }


    const [isToggled, setIsToggled] = useState(false);
    const onToggle = async (e) => {
        const id = e
        // alert(id)
        const response = await fetch(process.env.REACT_APP_IP+"/User/StartStopCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": id

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        //props.showAlert("Done Successfully ", "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: json.camp_status+" "+"Successfully",
            background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
            showConfirmButton: false,
            timer: 1500
          })
        getAllPosts()
        setIsToggled(!isToggled);


    }


    const [isHideToggled, setIsHideToggled] = useState(false);
    const onHideToggle = async (e) => {
        //const id = e
        // alert(id)
        var formdata = new FormData()
      formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP+"/User/HideCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        //props.showAlert("Done Successfully ", "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: "Success",
            background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
            showConfirmButton: false,
            timer: 1500
          })
        getAllPosts()
        setIsHideToggled(!isHideToggled);


    }


    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {


        const res = await axios.get(process.env.REACT_APP_IP+`/User/RestAllCamp?campaign=${campaigns}&&pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
       
      
      
      
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        //alert("clicked");
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [pace, setPace] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");
    const [audio, setAudio] = useState();
    const[listname,setList] = useState();



    // const setAudiooption = async(event) =>{
    //     setAudio()
    //     console.log(event)

    // }
    const addcampaignSubmit = async (event) => {
        event.preventDefault();
        //alert(`The name you entered was: ${campname}`)

        const response = await fetch(process.env.REACT_APP_IP+"/User/AddDeleteCampaignAction", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "campname": campname,
                "campaign_mode": campaign_mode,
                "maxtry": maxtry,
                // "pace": pace,
                "start_time": start_time,
                "end_time": end_time,
                "audioid": audio,
                "listname":listname
            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }
        setformyalert(!formyalert);
        getAllPosts()
        setCampname('')



    }




    const getPostData = (data) => {
        return (
            <>
               {/* <motion.div   
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}
              exit={{ opacity: 0,duration: 1.5  }}
          

               > */}

                         <motion.div
                            initial={{ x: -800 }} // Starting position (left side)
                            animate={{ x: 0 }} // Ending position (300px to the right)
                            transition={{ duration: 0.5 }} // Duration of 2 seconds
                            
                            >

              
                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12 mt-1'>

                        <div className='card'>



                            <div className=' text-black'>
                               <div className='card-header'>
                                <h4 className='text-center'>Existing Campaign</h4>
                                </div>

                                <div className="table-responsive p-1">

                                    <table className="table table-hover table-bordered checkoddata ignoreline">
                                        <thead className=" bg-darkblue text-white ">
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Delete</th>
                                                
                                                <th>Edit</th>
                                                <th>Pace</th>
                                                <th>Id | Audio</th>
                                               
                                                <th>Did</th>
                                                <th>Hide</th>
                                                <th>Status</th>
                                                <th>Starttime</th>
                                                <th>Endtime</th>
                                                <th>Listname</th>
                                                <th>DisposeSec</th>
                                               
                                               


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.campname}</td>
                                                        <td>{page.campaign_mode}</td>
                                                        

                                                        <td ><button className="btn btn-danger btn-sm" onClick={() => deleteCampaign(page.id)}>
                                                            
                                                        <i className="fa  fa-trash" ></i>
                                                            </button> </td>
                                                        
                                                        <td>
                                                            <button className=" btn btn-primary btn-sm" onClick={() => updateCampaign(page.id)}>
                                                                <i className="fa far fa-edit" ></i>
                                                            </button>

                                                        </td>
                                                        <td>{page.pace}</td>
                                                        <td>{page.audioid} | {page.audio_name}</td>
                                                       
                                                        <td>{page.did}</td>
                                                        {/* <td>{page.camp_status}</td> */}
                                                        
                                                        <td>

                                                        <label className="toggle-switch">
                                                            <input type="checkbox" checked={page.hiding === "YES"} onChange={() => onHideToggle(page.id)} />
                                                            <span className="switch" />
                                                        </label>

                                                        </td>

                                                        <td>

                                                            <label className="toggle-switch">
                                                                <input type="checkbox" checked={page.camp_status === "START"} onChange={() => onToggle(page.id)} />
                                                                <span className="switch" />
                                                            </label>

                                                        </td>
                                                        <td>{page.start_time}</td>
                                                        <td>{page.end_time}</td>
                                                        <td>{page.listname}</td>
                                                        
                                                        <td>{page.maxtry}</td>
                                                       



                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno :<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="page no" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>

                </motion.div>
            </>

        )

    }


    return (

    <>
   
    


        <div className='row topmargin '>


            <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit campaign</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className=" p-2" onSubmit={submiteditform}>
                                <div className="mb-2">

                                    <input type="text" className="form-control" id="ecampaignid" name="ecampaignid" value={ecampaignid}
                                        onChange={(e) => setEcampaignid(e.target.value)} required={true} readOnly={true} />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="title" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="ecampaign" name="ecampaign" value={ecampaign}
                                        onChange={(e) => setEcampaign(e.target.value)} required />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="description" className="form-label">Type</label>
                                    {/* <input type="text" className="form-control" id="ecampaignmode" name="ecampaignmode" value={ecampaignmode}
                                     onChange={(e) => setEcampaignmode(e.target.value)}  required/> */}
                                    <div className="mb-3">
                                        <select className="form-select" name="campaign_mode" value={ecampaignmode}
                                            onChange={(e) => setEcampaignmode(e.target.value)} >
                                            <option value="NA">Select Campaign Mode</option>
                                            <option value="OBD">OBD</option>
                                            <option value="OBDWithKeypress">OBDWithKeypress</option>
                                            
                                            <option value="Predictive">Predictive</option>
                                            <option value="Progressive">Progressive</option>
                                            <option value="Incoming">Incoming</option>
                                        </select>

                                    </div>
                                </div>

                                <div className=" mb-2 ">
                                      <label htmlFor="campname">DisposeSec</label>
                                        <input type="text" className="form-control" id="maxtry" placeholder="Enter maxtry"
                                            name="maxtry" value={maxtry}
                                            onChange={(e) => setmaxtry(e.target.value)} />
                                        
                                    </div>

                                    <div className=" mb-2 ">
                                      <label htmlFor="campname">Pace</label>
                                        <input type="text" className="form-control" id="maxtry" placeholder="Enter maxtry"
                                            name="maxtry" value={pace}
                                            onChange={(e) => setPace(e.target.value)} />
                                        
                                    </div>
                                <div className="mb-2">
                                    <label htmlFor="campname">Start Time</label>
                                    <input type="text" className="form-control" id="start_time" placeholder="Enter start_time"
                                        name="start_time" value={estarttime}
                                        onChange={(e) => setestarttime(e.target.value)} />

                                </div>

                                <div className="mb-2">
                                    <label htmlFor="end_time">End Time</label>
                                    <input type="text" className="form-control" id="end_time" placeholder="Enter start_time"
                                        name="end_time" value={eendtime}
                                        onChange={(e) => setEendtime(e.target.value)} />

                                </div>
                                <div className="mb-2">
                                    <label  className="form-label">Select Audio:</label>
                                    <select id="sel" className="form-select" name="audio"
                                        value={eselectaudio}
                                        onChange={(e) => setEeselectaudio(e.target.value)} required>
                                        <option value="">-- Select --</option>
                                        <option value="0">NA</option>

                                    </select>
                                </div>


                                <div className="mb-2">
                                    <label  className="form-label">Select List:</label>
                                    <select id="sellist" className="form-select" name="listname"
                                        value={eselectlist}
                                        onChange={(e) => setEeselectlist(e.target.value)} required>
                                        <option value="">-- Select List--</option>
                                        <option value="NA">NA</option>

                                    </select>
                                </div>




                                <div className="d-grid gap-2">
                                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                            {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                        </div>
                    </div>
                </div>
            </div>

            {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
            <div className='col-md-3 col-12 mt-1'>
                <form className=' card  ' style={{minHeight: "100px"

                }} onSubmit={addcampaignSubmit}>
                    <div className='card-header text-black'>
                        <h4 className='text-center'>Add Campaign</h4>
                    </div>
                    <div className=" mt-2">
                        <label htmlFor="campname" className="font-weight-bold">Campaign Name</label>
                        <input type="text" className="form-control" id="campname" placeholder="Enter campname"
                            name="campname" value={campname}
                            onChange={(e) => setCampname(e.target.value)} required />
                        
                    </div>

                    {/* <div className="form-floating mb-3 mt-1">
                            <input type="text" className="form-control" id="campaign_mode" placeholder="Enter campaign_mode"
                             name="campaign_mode"  value={campaign_mode} 
                             onChange={(e) => setcampaign_mode(e.target.value)} />
                            <label htmlFor="campaign_mode">Campaign Mode</label>
                        </div> */}
                    <div className="">
                    <label htmlFor="sel1" className="">Type:</label>
                        <select className="form-select" name="campaign_mode" value={campaign_mode}
                            onChange={(e) => setcampaign_mode(e.target.value)} >
                            <option value="NA">Campaign Type</option>
                            <option value="OBD">OBD</option>
                            <option value="OBDWithKeypress">OBDWithKeypress</option>
                            <option value="Predictive">Predictive</option>
                            <option value="Progressive">Progressive</option>
                            <option value="Incoming">Incoming</option>
                            
                        </select>
                        
                    </div>
                    <div className=" mt-1 mb-1 ">
                    <label htmlFor="campname">DisposeTime</label>
                        <input type="text" className="form-control" id="maxtry" placeholder="disposetime"
                            name="maxtry" value={maxtry}
                            onChange={(e) => setmaxtry(e.target.value)} />
                       
                    </div>

                    {/* <div className=" ">
                    <label htmlFor="pace">Pace</label>
                        <input type="number" className="form-control" id="pace" placeholder="pace"
                            name="maxtry" value={pace}
                            onChange={(e) => setPace(e.target.value)} />
                       
                    </div> */}

                    <div className=" mt-1 mb-1 ">
                    <label htmlFor="campname">Start Time</label>
                        <input type="text" className="form-control" id="start_time" placeholder="Enter start_time"
                            name="start_time" value={start_time}
                            onChange={(e) => setstart_time(e.target.value)} />
                       
                    </div>

                    <div className="">
                    <label htmlFor="end_time">End Time</label>
                        <input type="text" className="form-control" id="end_time" placeholder="Enter start_time"
                            name="end_time" value={end_time}
                            onChange={(e) => setend_time(e.target.value)} />
                       
                    </div>


                    <div className=" mt-1 mb-1 ">
                        {/* <Select defaultValue={selectedOption} options={options}
                               name="audio" value={audio} onChange={setSelectedOption} /> */}

                     <label>Select Audio:</label>
                        <select id="selmain" className="form-select" name="audio" onChange={(e) => setAudio(e.target.value)} required>
                            <option value="">-- Select --</option>
                            <option value="0">NA</option>

                        </select>
                       
                    </div>

                    <div className="">
                    <label>Select List:</label>
                         <select id="list" className="form-select" name="listname"  onChange={(e) => setList(e.target.value)} required>
                            <option value="">-- Select List --</option>
                            <option value="">NA</option>

                        </select>
                        
                    </div>


                    <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                </form>

            </div>


            <div className="col-md-9 col-12">

                {/* Display all the posts */}
                {posts}

                <div className="row">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12">


                        <ReactPaginate
                             previousLabel={<i style={{color:'blue'}} className="fa fa-arrow-left fa-lg">PRE</i>}
                             nextLabel={<i style={{color:'blue'}} className="fa fa-arrow-right fa-lg">NEXT</i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination pagination-sm"}
                            subContainerClassName={"pages pagination pagination-sm"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>
                <div className="col-md-3 col-12 text-center">

                        <form onSubmit={searchCampaign} className=''>
                            <input type="text" className="   form-control" id="searchcamp" name="searchcamp"
                                placeholder="Campaign Name" 
                                onChange={(e) => setCampaigns(e.target.value)}
                            // onChange={onInputChange}
                                />
                                <input type='submit' className='mt-1 btn btn-primary btn-mysm' value="search" />

                        </form>

                </div>  



            </div>




        </div>
        </>
    )


}


export default AddCampaign
