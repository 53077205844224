import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';

const AddDisposition = () => {



    const [camp , setCamp] = useState("")
    const [disp , setDisp] = useState("")
    const [posts, setAllPosts] = useState([]);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0)

    const [ctl , setCtl] = useState("")
    const [cal , setCal] = useState("")

    
    



    //All Campaign option

const optionforcamp = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP+"/User/allGroupbyuserid", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('campid');
    elelist.innerHTML = ""+('<option value="NA">NA</option>');

    for (var i = 0; i < json.length; i++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['id'] + '">' + json[i]['id'] + ' | '+json[i]['campname'] + '</option>';
    }
}

useEffect(() => {
    optionforcamp()
}, [])


const AddDispoFun = async(e) =>{
    e.preventDefault();
//alert(">>>>"+camp+">>>>"+disp)
//console.log("id>>>>>>>>" + e)
   
var formdata = new FormData()
formdata.append('campid', camp)
formdata.append('dispo', disp)
formdata.append('ctl', ctl)
formdata.append('cal', cal)



const response = await fetch(process.env.REACT_APP_IP+"/User/AddDispo", {
    method: 'POST',     
    headers: {
        //  'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
    },
    body: formdata
    // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
});
const json = await response.json()
console.log(json);
if (json.type === "OK") {
    // props.showAlert(json.msg, "success");
    Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
    })
} else {
    //props.showAlert(json.msg, "danger");
    Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
    })
}


// setGroupname("NA")
//  set

getAllPosts()
//setCamp("")
setDisp("")

}

//getAllPosts
const getAllPosts = async () => {
    const res = await axios.get(process.env.REACT_APP_IP+`/User/AllDispo?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    const data = res.data;
    const postData = getPostData(data)
    // Using Hooks to set value
    setAllPosts(postData)
    // setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
}
useEffect(() => {
    getAllPosts()
}, [offset])


const getPostData = (data) => {
    return (
        <>

            <div className='row '>

                {/* <AddCampaignform /> */}


                < div className='col-md-12 col-12'>

                    <div className='card mt-1' >



                        <div className='mybgcolor'>
                                <div className='card-header text-black'>
                                    <h4 className='text-center'>Existing Disposition</h4>
                                </div>

                            <div className="table-responsive p-1">

                                <table className="table table-hover table-bordered checkoddata ignoreline">
                                    <thead className="bg-darkblue text-white">
                                        <tr>
                                            <th>S.N</th>
                                           
                                            <th>Campaign</th>
                                            
                                            <th>Disposition</th>
                                            <th>ConvertToLead</th>
                                            <th>Date Time</th>
                                            <th>Date Added</th>
                                            

                                            <th>Action</th>
                                            {/* <th>Delete</th> */}


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.content.map(page => {
                                            return (
                                                <tr key={page.id}>
                                                    <td>{page.id}</td>
                                                   
                                                    <td>{page.campid} | {page.campaign}</td>
                                                    
                                                    <td>{page.dispo}</td>
                                                    <td>{page.lead}</td>
                                                    <td>{page.caltype}</td>
                                                    <td>{page.createdate}</td>
                                                    

                                                    <td>
                                                        {/* <button className=" mx-2 btn btn-primary disabled" >
                                                            <i className="fa fa-edit" ></i>
                                                        </button> */}
                                                  <button className='btn btn-danger btn-mysm'>
                                                        
                                                  <i className="fa fa-trash mx-2"
                                                   onClick={() => deleteDispo(page.id)}
                                                   
                                                   ></i>

                                                  </button>
                                                

                                                    </td>





                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                    placeholder="gotopage" 
                                    onChange={onInputChange}
                                     />

                            </div>
                        </div>
                    </div>


                </div>

            </div>


        </>

    )

}

const deleteDispo = async (e) => {
    // e.preventDefault();
     //refaddgroupagent.current.click();
     console.log("id>>>>>>>>" + e)
    
     var formdata = new FormData()
     formdata.append('id', e)
   
     const response = await fetch(process.env.REACT_APP_IP+"/User/deleteDispo", {
         method: 'POST',     
         headers: {
             //  'Content-Type': 'application/json',
 
             "Authorization": `Bearer ${localStorage.getItem('token')}`
         },
         body: formdata
         // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
     });
     const json = await response.json()
     console.log(json);
     if (json.type === "OK") {
         // props.showAlert(json.msg, "success");
         Swal.fire({
             position: 'top-end',
             icon: 'success',
             title: json.msg,
             background: '#e9ecef',
             // title: 'Done Successfully ',
             //background: primary,
             showConfirmButton: false,
             timer: 2500
         })
     } else {
         //props.showAlert(json.msg, "danger");
         Swal.fire({
             position: 'top-end',
             icon: 'error',
             title: json.msg,
             background: '#e9ecef',
             // title: 'Done Successfully ',
             //background: primary,
             showConfirmButton: false,
             timer: 2500
         })
     }
     getAllPosts()
 }

const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

}
const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
}



  return (
    <>
    

<div className='row'>

 <div className='col-md-3'>
            <form className=' card mt-1 ' style={{minHeight: "100px"}} 
             onSubmit={AddDispoFun}
            >
            <div className='card-header text-black'>
            <h4>Add Disposition</h4>
            </div>
            <div className='car-body'>
                <div className=" mt-2">
                        <label htmlFor="sel1" className="form-label">Select Campaign:</label>
                            <select className="form-select" name="campaign_mode" id="campid"
                            onChange={(e) => setCamp(e.target.value)}
                               // value={campaign_mode}
                               // onChange={(e) => setcampaign_mode(e.target.value)}
                                 >
                               
                                
                            </select>
                            
                </div>
            <div className=" mt-2 mb-2 ">
            <label htmlFor="dispo">Disposition</label>
            <input type="text" className="form-control" id="dispo" placeholder="dispo"
            name="dispo" 
             value={disp}
             onChange={(e) => setDisp(e.target.value)} 
            required />


            <div className="mt-1">
                    <label>ConvertToLead:</label>
                        <select className="form-select" name="ctl" value={ctl}
                            onChange={(e) => setCtl(e.target.value)} required>
                            <option value="">Select</option>
                           
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            
                            
                        </select>
                        
            </div>
            <div className="mt-1">
                    <label>Date Time:</label>
                        <select className="form-select" name="cal" value={cal}
                            onChange={(e) => setCal(e.target.value)} required>
                            <option value="">Select</option>
                           
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            
                            
                        </select>
                        
            </div>

            <input className=" form-control text-white btn btn-primary mt-3" type="submit" value="Submit" />

            </div>
            




            </div>

            </form>
 </div>
<div className='col-md-9'>

    {posts}
                <div className="row mb-5">
                        <div className="col-md-3 col-12">
                            
                        </div>

                        <div className="col-md-6 col-12">
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                breakLinkClassName={"break-mef"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                forcePage={offset - 1}

                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}

                            />
                        </div>

               </div>
    
 </div>

</div>

    
    
    
    </>
  )
}

export default AddDisposition