import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import ReactAudioPlayer from 'react-audio-player';
import { motion } from 'framer-motion';
// import Navbar from './Navbar';
import Swal from 'sweetalert2';
import AudioPlayer from './AudioPlayer';




const UploadAudio = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    useEffect(() => {
        document.title = "Upload Audio"
    }, []);





    const [isToggled, setIsToggled] = useState(false);
    const onToggle = async (e) => {
        const id = e
        // alert(id)
        const response = await fetch(process.env.REACT_APP_IP + "/Admin/ApproveAudio", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": id

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });

        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }
        getAllPosts()
        setIsToggled(!isToggled);


    }


    const el = useRef();

    const [uploadFile, setUploadFile] = useState();
    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }

    const uploadaudioonsubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('file', uploadFile)

        const response = await fetch(process.env.REACT_APP_IP + "/User/AudioUpload", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }
        getAllPosts()

        el.current.value = "";



        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }



   





    const [pagesize, setPagesize] = useState("10");

    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP + `/Admin/RestAllAudio?pageNumber=${offset - 1}&&size=${pagesize}`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
      }, [offset,pagesize])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>

                <div className='row mt-1'>

                    {/* <motion.div   
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}
              exit={{ opacity: 0,duration: 1.5  }}
               > */}

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12'>

                        <div className='card p-1' style={{
                            //  minHeight:"200px"
                        }}>

                            <div className=' text-black'>
                                <div className='card-header text-center'>Existing Audio</div>

                                <div className="table-responsive">

                                    <table className="table table-hover table-bordered checkoddata ignoreline">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>User Id</th>
                                                <th>Audio Name</th>
                                                <th>Approve Status</th>
                                                {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ? <th>Approve</th> : ''}
                                                <th>Play</th>

                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.userid}</td>
                                                        <td>{page.audioname}</td>
                                                        <td>{page.approve}</td>
                                                        {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                            <td> <label className="toggle-switch">
                                                                <input type="checkbox" checked={page.approve === "Approved"} onChange={() => onToggle(page.id)} />
                                                                <span className="switch" />
                                                            </label></td> : ''}
                                                        <td>
                                                            {/* <ReactAudioPlayer
                                                                src={'http://http://45.63.19.202:8080/ObdAudio/'+page.userid+'/'+page.audioname}

                                                                controls /> */}



                                                            <AudioPlayer src={process.env.REACT_APP_REC_LINK_Dialeraudio + page.userid + '/' + page.audioname} />


                                                        </td>




                                                        <td className='text-center'>
                                                            <div className='btn btn-danger btn-mysm'>
                                                                <i className="fa fa-trash mx-2" ></i>
                                                            </div>
                                                        </td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                  

                                </div>
                            </div>
                        </div>


                    </div>
                    {/* </motion.div> */}

                </div>


            </>

        )

    }


    return (

        <>
            {/* <Navbar /> */}



            <div className='row mt-1 ms-1 me-2'>




                {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
                <div className='col-md-3 col-12 text-black'>


                    <form className='card mybgcolor mt-1' onSubmit={uploadaudioonsubmit}>
                        <div className='card-header'>
                            <div className='text-center'>Upload Audio</div>
                        </div>

                        <div className="mb-2 mt-2">

                            <input className=" form-control form-control-sm" ref={el}
                                id="formFileSm" name="file" type="file" onChange={onchangfun} required />
                        </div>



                        <input className="btn btn-primary mt-1" type="submit" value="Submit" />
                    </form>

                </div>


                <div className="col-md-9 col-12">

                    {/* Display all the posts */}
                    {posts}

                    <div className='row mb-5 mt-1'>
                <div className='col-md-1 float-end'>
                  <select className='float-end form-select form-select-sm' 
                  //onChange={(e) => setPagesize(e.target.value)}
                  onChange={(e) => setPagesize(e.target.value)}
                  //  value={pagesize}
                  >
                     <option value="10">10</option>
                    <option value="5">5</option>
                    
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className='col-md-1'>
                  <input type="number" className="gotopage" id="gotopage" aria-describedby="gotopage"
                    placeholder="page" onChange={onInputChange} />
                </div>
                <div className='col-md-1'>

                </div>
                <div className='col-md-6'>
                
                 
                  <ReactPaginate
                    previousLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-left fa-lg"></i>}
                    nextLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-right fa-lg"></i>}
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    breakLinkClassName={"break-mef"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={offset - 1}

                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                    
             

                </div>
                
                
              
              </div>



                </div>




            </div>
        </>
    )


}


export default UploadAudio
