

// import '../App.css';
import { useEffect, useState} from 'react'
import { useNavigate ,Link} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import Login from './Login';
// import Navbar from './Navbar';
import Swal from 'sweetalert2';
import { FiTrash } from 'react-icons/fi';
import { GiVibratingSmartphone } from 'react-icons/gi';
import { FiPhoneCall,FiPhoneIncoming } from 'react-icons/fi';
import {FcMissedCall } from 'react-icons/fc';

import { MdOutlineAddIcCall } from "react-icons/md";
import { Pie } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

import { Chart as ChartJS, ArcElement, Tooltip, Legend,PointElement, LineElement } from 'chart.js';
import {
  
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
   
  } from 'chart.js';

import { FaUsers } from 'react-icons/fa';

import { motion } from 'framer-motion';


export const Dashboard = (props) => {

    let history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
        } else {
            history('/');
        }
    }, [])

    // useEffect(() => {
    //     const currentUrl = window.location.href;
    //     console.log(">>>>currentUrl>>>>"+currentUrl);
    
    //     // You can also access specific parts of the URL like pathname, search, etc.
    //     const pathname = window.location.pathname;
    //     const searchParams = window.location.search;
    //     console.log(">>>>>pathname---searchParams>>>>>"+pathname, searchParams);
    
    //     // Do something with the URL information
    //     // ...
    //   }, []);
   

    ChartJS.register(ArcElement, Tooltip, Legend,PointElement,LineElement);

     ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );


 //line chart
 
  // Sample data for the chart
  const data = {
    labels: ['MON', 'TUE', 'WED', 'THU', 'FRI','SAT'],
    datasets: [
      {
        label: 'Incoming Call',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        pointRadius: 4,
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
      },
      {
        label: 'Answered',
        data: [5, 10, 7, 12, 8, 15],
        backgroundColor: 'rgba(192, 75, 192, 0.2)',
        borderColor: 'rgba(192, 75, 192, 1)',
        borderWidth: 2,
        pointRadius: 4,
        pointBackgroundColor: 'rgba(192, 75, 192, 1)',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };


    useEffect(() => {
        document.title = "Home"
     }, []);

     var today;

     // console.log(today);
     const [offset, setOffset] = useState(1); 

     

    

   useEffect(() => {
    
      const timer = setInterval(async () => {
        if(props.commonProp==="/Realtime")
        {
       
      
           today = Math.round((new Date()).getTime() / 1000)
           if (localStorage.getItem('roles') === 'ROLE_ADMIN')
           {
          //console.log(today);
              const res2 = await axios.get(process.env.REACT_APP_IP+`/User/getLiveAgentRecord?pageNumber=${offset - 1}&&size=20`,
              { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
     
              const data = res2.data;
              const postData = getPostData(data)
              setAllPosts(postData)
              console.log(">>>>>"+posts)
              setPageCount(Math.ceil(data.totalPages))
        }
    }else{
            return null;
        }
       return () => clearInterval(timer);
      }, 20000)

    


   }, [])

  
    const [postsPerPage] = useState(10);
    const [postsPerPage2] = useState(10);
    
    const [offset2, setOffset2] = useState(1);
    const [offsetagent, setOffsetagent] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [agentposts, setAgentposts] = useState([]);
    const [posts2, setAllPosts2] = useState([]);
    const [agentpageCount, setAgentpageCount] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [pageCount2, setPageCount2] = useState(0)


    const [inctotal, setInctotal] = useState(0)
    const [anstotal, setAnstotal] = useState(0)
    const [missedtotal, setMissedtotal] = useState(0)
    const [totalloginagent, setTotalloginagent] = useState(0)
    

    const totalLoginAgent = async () => {  

        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/countLoginAgent`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        setTotalloginagent(data)
    }
      }

      useEffect(() => {
        totalLoginAgent()
    }, [])



    const [totalclicktocall, setTotalclicktocall] = useState(0)
    

    const totalclicktocallFun = async () => {  

        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/countAllClicktocall`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        setTotalclicktocall(data)
    }
      }

      useEffect(() => {
        totalclicktocallFun()
    }, [])


    const [totalredial, setTotalredial] = useState(0)
    

    const totalredialfun = async () => {  

        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/countAllRedial`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        setTotalredial(data)
    }
      }

      useEffect(() => {
        totalredialfun()
    }, [])


    const [totalinccalls, setTotalinccalls] = useState(0)
    

    const totalinccallfuns = async () => {  

        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/countAllIncCall`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        setTotalinccalls(data)
    }
      }

      useEffect(() => {
        totalinccallfuns()
    }, [])


    const totalMisscall = async () => {  


        axios.get(process.env.REACT_APP_IP+`/User/countAllMissCall`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
          })
            .then(response => {
                const data = response.data;
                //    // console.error(">>>>>>>error>>>>>>>");  
                  //  setInctotal(data)
                    setMissedtotal(data)
            })
            .catch(error => {
              //alert("Error")
              console.log(">>>>>>>>>>"+error.response.status)
              if(error.response.status)
              {
                localStorage.removeItem('token');
                localStorage.removeItem('roles');
               
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: 'Logout Due to  Token Expire ',
                    showConfirmButton: false,
                    timer: 2500
                })
                history('/Dashboard');
              }

            });
       
       }
      

      useEffect(() => {
        totalMisscall()
    }, [])

    const totalinccall = async () => {  

        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/counttotalcallbyflag`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        setInctotal(data)
    }
      }

      useEffect(() => {
        totalinccall()
    }, [])

    const totaanscall = async () => {  

        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/countAllAnsCall`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        setAnstotal(data)
    }
      }

      useEffect(() => {
        totaanscall()
    }, [])





    useEffect(() => {   
        const timera = setInterval(async () => {      
            
            if(props.commonProp==="/Realtime")
            {
             today = Math.round((new Date()).getTime() / 1000)
             if (localStorage.getItem('roles') === 'ROLE_ADMIN')
             {
            //console.log(today);
                const res = await axios.get(process.env.REACT_APP_IP+`/User/getAgentLiveAgentRecord?pageNumber=${offsetagent - 1}&&size=20`,
                { 
                    headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }

               })
       
                
                const dataagent = res.data;
                const postDataagent = getPostDataforagent(dataagent)
                setAgentposts(postDataagent)               
                setAgentpageCount(Math.ceil(dataagent.totalPages))
               // console.log(">>>agentposts>>"+agentposts)
          }
        }else{
            return null;
        }
         return () => clearInterval(timera);
        }, 20000)     
  
     }, [])


    // const getAllPosts = async () => {
    //     const res = await axios.get(`http://localhost:8080/withouttokenhello`)
    //     const data = res.data;
    //     const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    //     // For displaying Data
    //     const postData = getPostData(slice)

    //     // Using Hooks to set value
    //     setAllPosts(postData)
    //     setPageCount(Math.ceil(data.length / postsPerPage))
    // }

    const callBarge = async(e) => {
        console.log(">>>>>>>>>>>>>>>>callbarge>>>>>>>>>>>>>>>>"+e)
        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP+"/User/Clicktocallbarge", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
    
                headers: {
                    //  'Content-Type': 'application/json',
    
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            const json = await response.json()
            console.log(json);
            if (json.type === "OK") {
                // props.showAlert(json.msg, "success");
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Success',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                //props.showAlert(json.msg, "danger");
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 4500
                })
            }
    
        
       }


       const deleteLivecall = async(e) => {
        console.log(">>>>>>>>>>>>>>>>deleteLivecall>>>>>>>>>>>>>>>>"+e)
        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP+"/User/deleteLivecallByid", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
    
                headers: {
                    //  'Content-Type': 'application/json',
    
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            const json = await response.json()
            console.log(json);
            if (json.type === "OK") {
                // props.showAlert(json.msg, "success");
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Success',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                //props.showAlert(json.msg, "danger");
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 4500
                })
            }
    
        
       }

    const[totalagent,settotalagent] = useState(0)
    const totalcallcenteragent = async () => {  

        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/Admin/CountAgentCallcenter`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalagent(data)
    }
      }

      useEffect(() => {
        totalcallcenteragent()
    }, [])



    const[totalcampcallcenter,settotalcampcallcenter] = useState(0)
    const totalcampcallcenterfun = async () => {  
        
        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/Admin/CountTotalCampaignCallcenter`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalcampcallcenter(data)
    
      }
    }

      useEffect(() => {
        totalcampcallcenterfun()
    }, [])



    const[totalpendingdatacallcenter,settotalpendingdatacallcenter] = useState(0)
    const totalpendingdatacallcenterfun = async () => {  
        if (localStorage.getItem('token'))
        {
        const res = await axios.get(process.env.REACT_APP_IP+`/Admin/CountTotalPendingdataCallcenter`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalpendingdatacallcenter(data)
        }
      }

      useEffect(() => {
        totalpendingdatacallcenterfun()
    }, [])


    const getPostDataforagent = (dataagent) => {

        return (
            <>                                          
                                <tbody>
                                    {dataagent.content.map(page => {

                                    
                                        return (                                    
                                           
                                            <tr key={page.id} className={`p2 ${page.status === "BUSY" ? "dashboardbusycolor" : ""}`}>
                                                <td>{page.username} | {page.sip}</td>
                                                <td>{page.custmobile} </td>
                                                <td>   
                                                     {page.status}
                                                     </td>
                                               
                                                <td>{page.campaign}</td>
                                                {/* <td>{page.id}</td> */}
                                                {/* <td>

                                                            { Math.round( today - page.itime)}

                                                </td> */}
                                                {/*                                                 
                                                <td><a style={{color:'white'}}className="fa fa-whatsapp
                                                 fa-lg btn btn-success" 
                                                 href={'https://web.whatsapp.com/send?phone='+91+page.custnumber+'&text=Hii&source=&data=&app_absent=0'}
                                                  target="_blank"></a></td> */}

                                                 <td><button className='btn btn-primary  btn-sm' onClick={(e) => callBarge(page.id)} >Barge</button></td>
                                                <td><button className='btn btn-primary  btn-sm' onClick={(e) => callBarge(page.id)} >Whis</button></td> 
                                                {/* <td><button className='btn btn-danger  btn-sm' onClick={(e) => deleteLivecall(page.id)}>
                                                      <FiTrash   />
                                                      </button>
                                                      
                                                      </td>  */}
                                                 
                                            </tr>
                                              

                                        );
                                  
                                    })}
                                </tbody>                                                         
            </>

        )
    }





    

    const getAllPosts2 = async () => {
        const res2 = await axios.get(process.env.REACT_APP_IP + `/User/getRecord`,
            { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data2 = res2.data;
        //const slice2 =[];
        if(offset2>1)
        {
             const slice2 = data2.slice(postsPerPage2*(offset2-1) - 1, postsPerPage2*(offset2-1) - 1 + postsPerPage2)
             const postData2 = getPostData2(slice2)

             //     // Using Hooks to set value
             setAllPosts2(postData2)
             setPageCount2(Math.ceil(data2.length / postsPerPage2))

        }else{
            const slice2 = data2.slice(offset2 - 1, offset2 - 1 + postsPerPage2)
            const postData2 = getPostData2(slice2)

            //     // Using Hooks to set value
            setAllPosts2(postData2)
            setPageCount2(Math.ceil(data2.length / postsPerPage2))
        }
        
       // const slice2=abslice;
       //const slice2 = data2.slice(postsPerPage2)
        // For displaying Data
       
    }


    const handlePageClick = (event) => {
        const selectedPage = event.selected;

        console.log(">>>>>>selectedPage>>>>>>>>"+selectedPage)
        setOffset(selectedPage + 1)
    };

    const handlePageClick2 = (event) => {
        const selectedPage2 = event.selected;
        setOffset2(selectedPage2 + 1)
    };

    //  useEffect(() => {
    //     if (localStorage.getItem('token')) {
    //      getAllPosts()
    //     }
    //  }, [offset])


    useEffect(() => {
        if (localStorage.getItem('token')) {
            getAllPosts2()

        }
        const interval = setInterval(() => {
            if (localStorage.getItem('token')) {
                getAllPosts2()
    
            }
            //getAllPosts2(); // your function to fetch data
        }, 15000);
    
        return () => clearInterval(interval);
      }, [offset2]);
    

    //  useEffect(() => {
    //     if (localStorage.getItem('token')) {
    //         getAllPosts2()

    //     }
        
    //  }, [offset2])


    const { showAlert } = props

    const getPostData2 = (data2) => {

        return (
            <>

               
                                <tbody>
                                     {data2.map(page2 => {
                                        return (
                                            <tr key={page2.id}>
                                                <td>{page2.list}</td>
                                                <td>{page2.campaign}</td>
                                                <td>{page2.pendingdata}</td>
                                                
                                                <td>{page2.dialeddata}</td>
                                                <td>{page2.totaldatainlist}</td>

                                            </tr>
                                        );
                                    })} 
                                </tbody>                          

            </>




        )
    }
    const getPostData = (data) => {

        return (
            <>
            

             

                {/* <h2>This is home page</h2> */}

                {/* call */}
               

                   

                    {/* <div className="col-sm-12 col-12 my-2" >
                        <h5>Last Call Report</h5>
                        <div className="table-responsive">
                        <table className="table table-hover bg-info text-white table-striped ">
                                <thead className='bg-darkblue'>
                                    <tr>
                                       
                                        <th>CustomerNumber</th>
                                        <th>Campaign</th>
                                        <th>Dialstatus</th>
                                        <th>Dialtime</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.content.map(page => {
                                        return (
                                            <tr key={page.id}>
                                                
                                                <td>{page.CustomerNumber}</td>
                                                <td>{page.campaign}</td>
                                                <td>{page.dialstatus}</td>
                                                <td>{page.dialtime}</td>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div> */}




                        
                                
                                <tbody>
                                    {data.content.map(page => {

                                    
                                        return (

                                        //     <tr key={page.id}>
                                        //     <td>8810692314</td>
                                        //     <td>1207182964</td>
                                        //     <td>9560179221</td>
                                        //     <td>1</td>
                                        //     <td>00:00:45</td>
                                        // </tr>
                                        // Math.round( today - page.itime) === 1 ? (
                                          //  Math.round( today - page.itime) < 3600 ? (
                                            Math.round( today - page.itime) < 360000000 ? (
                                            <tr key={page.id}>
                                                <td>{page.custnumber}</td>
                                                <td >
                                                            <div className='row '>
                                                            {/* <div className='col-md-3 col-6'>
                                                                    <i style={{color:'blue'}}className=" fa fa-phone fa-lg"></i>
                                                            </div> */}
                                                            <div className='col-md-12 col-6'>
                                                                    <div style={{ marginTop:'6px'}} className="dots" >

                                                                    <div><GiVibratingSmartphone color="blue" size="16px" /></div>
                                                                    <div className='mx-1'><GiVibratingSmartphone color="blue" size="18px" /></div>
                                                                            <div><GiVibratingSmartphone color="blue" size="20px" /></div>
                                                                                
                                                                            <div><GiVibratingSmartphone color="blue" size="22px" /></div>
                                                                           
                                                                            {/* <div><GiVibratingSmartphone color="blue" size="16px" /></div> */}
                                                                    </div>
                                                            </div>
                                                        </div>
                                                </td>
                                               
                                                <td>{page.agentnum} | {page.username}</td>
                                                <td>{page.did}</td>
                                                <td>

                                                            { Math.round( today - page.itime)}

                                                </td>
{/*                                                 
                                                <td><a style={{color:'white'}}className="fa fa-whatsapp fa-lg btn btn-success" href={'https://web.whatsapp.com/send?phone='+91+page.custnumber+'&text=Hii&source=&data=&app_absent=0'} target="_blank"></a></td> */}

                                                 <td><button className='btn btn-primary  btn-sm' onClick={(e) => callBarge(page.id)} >Barge</button></td>
                                                <td><button className='btn btn-primary  btn-sm' onClick={(e) => callBarge(page.id)} >Whis</button></td> 
                                                <td><button className='btn btn-danger  btn-sm' onClick={(e) => deleteLivecall(page.id)}>
                                                      <FiTrash   />
                                                      </button>
                                                      
                                                      </td> 
                                                 
                                            </tr>
                                              ):null

                                        );
                                  
                                    })}
                                </tbody>



                                
                           
                 
               

            </>




        )
    }

    // chart 
const chartdata = {
    labels: ['Totalcall', 'Answer', 'Abandone','C2c', 'Redial', 'Inc'],
    datasets: [
      {
        // label: '#',
        label: ['Call-Data'],
        data: [inctotal, anstotal, missedtotal,totalclicktocall,totalredial,totalinccalls],
        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        backgroundColor: [
            
        //   'rgba(255, 99, 132, 0.2)',
      
        //   'rgba(54, 162, 235, 0.2)',
        'blue',
        'green',
        '#ff9800',
        '#3addce',
        '#cb11eb',
        'black',
        //   'rgba(255, 206, 86, 0.2)',
        //  'blue'
        //   'rgba(75, 192, 192, 0.2)',
          
        ],
       
        borderWidth: .2,
      },
    ],
  };


    return (      
        <>  
    <div className="ms-3 me-3" > 
         <div className=''>
                <div className=''>
                    <div className="mt-1" > 
                        <div className='row fordashbg '>
                        <div className=' col-md-2 p-3'>
                            
                            <div className=' border-2 border-bottom border-primary '>
                                <div>
                                <FiPhoneCall color="blue" />
                                </div>
                                <div>
                                    <b>Total Calls</b>
                                    
                                </div>
                                <div className=''>
                                <span style={{fontSize:"10px"}}>Last 7 Days</span>
                                </div>
                                <div className='mb-2'>
                                <b style={{fontSize:"24px"}}>{inctotal}</b>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className='col-md-2 p-3'>
                            <div className='border-2 border-bottom border-success'>
                                <div>
                                    <FiPhoneCall color="blue" />
                                </div>
                                <div>
                                    <b>Answer Calls</b>
                                    
                                </div>
                                <div className='mb-0'>
                                <span style={{fontSize:"10px"}}>Last 7 Days</span>
                                </div>
                                <div className='mb-2'>
                                <b style={{fontSize:"24px"}}>{anstotal}</b>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 p-3'>
                            <div className='border-2 border-bottom border-danger'>
                                <div>
                                    <FcMissedCall />
                                </div>
                                <div>
                                    <b>Abandoned Calls</b>
                                    
                                </div>
                                <div className='mb-0'>
                                <span style={{fontSize:"10px"}}>Last 7 Days</span>
                                </div>
                                <div className='mb-2'>
                                <b style={{fontSize:"24px"}}>{missedtotal}</b>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 p-3'>
                            <div className='border-2 border-bottom border-danger'>
                                <div>
                                <MdOutlineAddIcCall color="green"/>
                                </div>
                                <div>
                                    <b>Click2Call</b>
                                    
                                </div>
                                <div className='mb-0'>
                                <span style={{fontSize:"10px"}}>Last 7 Days</span>
                                </div>
                                <div className='mb-2'>
                                <b style={{fontSize:"24px"}}>{totalclicktocall}</b>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 p-3'>
                            <div className='border-2 border-bottom border-danger'>
                                <div>
                                <MdOutlineAddIcCall color="green"/>
                                </div>
                                <div>
                                    <b>Redial</b>
                                    
                                </div>
                                <div className='mb-0'>
                                <span style={{fontSize:"10px"}}>Last 7 Days</span>
                                </div>
                                <div className='mb-2'>
                                <b style={{fontSize:"24px"}}>{totalredial}</b>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 p-3'>
                            <div className='border-2 border-bottom border-danger'>
                                <div>
                                    
                                    <FiPhoneIncoming color="black" />
                                </div>
                                <div>
                                    <b>Incoming</b>
                                    
                                </div>
                                <div className='mb-0'>
                                <span style={{fontSize:"10px"}}>Last 7 Days</span>
                                </div>
                                <div className='mb-2'>
                                <b style={{fontSize:"24px"}}>{totalinccalls}</b>
                                </div>
                            </div>
                        </div>
                        </div>
                        
                        <div className='row'>
                        <div className='col-md-6 mt-2'>
                             <p className='text-center card-header'>Calls Graph</p>
                             <div className='mt-5'>
                                  <Doughnut style={{maxHeight:"220px",width:"300px",marginTop:"50px"}}  data={chartdata} />
                            </div>
                        </div>

                        <div className=' col-md-6 mt-2 '>
                      
                            <div className="table-responsive ">
                            
                                <p className='text-center card-header'>Pending Calls</p>
                            
                                <table className="table table-hover table-bordered checkoddata">
                    
                                    <thead className='bg-darkblue text-white'>
                                            
                                        <tr>
                                            
                                            <th>ListName			</th>
                                            <th>AssignUser</th>
                                            <th>Pendingdata</th>
                                            <th>Dialeddata</th>
                                            <th>Totaldata</th>

                                        </tr>
                                    </thead>
                                    
                                    {posts2}
                                    
                                </table>

                            </div>
                            <div className='row'>
                            <div className="col-sm-5" ></div>
                             <div className="col-sm-7" >
                                      {/* Using React Paginate */}
                                      <ReactPaginate
                                          previousLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-left fa-lg"></i>}
                                          nextLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-right fa-lg"></i>}
                                          breakLabel={"..."}
                                          breakClassName={"break-me"}
                                          pageCount={pageCount}
                                          onPageChange={handlePageClick}
                                          containerClassName={"pagination"}
                                          subContainerClassName={"pages pagination"}
                                          activeClassName={"active"} />
                              </div>


                            </div>
                             


                      </div>
                     
                      </div>
                             <div className="row mt-2">
                                  
                                 
                                  <div className="col-sm-3" ></div>
                              </div>
                      

                    </div>
               
                </div>
                <div>              
                </div>
                </div> 
    </div>

        </> 
    )



}
export default Dashboard

